import React, { useState } from "react";
import { DownloadTenantDirectoryPdfAPI } from "./reportsAPI";
import { ButtonIcon } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import ExportCSV from "../../Widgets/ExportCSV";
import {
  GetDateInMMDDYYYY,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";

export const TenantDirectoryReport = (props) => {
  const [tableData, setTableData] = useState(props.data);

  const downloadPDF = () => {
    let requestData = {
      propertyId: props.property.ID,
    };
    DownloadTenantDirectoryPdfAPI(requestData).then((res) => {
      // const link = document.createElement("a");
      // link.href = res.data.url;
      // link.setAttribute("download", "tenantDirectoryReport.pdf"); // You can set a default filename
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      window.open(res.data.url, "_blank");
    });
  };

  return (
    <>
      <div className="flex justify-end items-center my-4 gap-4 mb-2 mx-2">
        <ExportCSV
          data={tableData}
          keys={[
            { key: "unitNumber", title: "Unit Number" },
            { key: "residentCode", title: "Resident Code" },
            { key: "name", title: "Tenant Name" },
            {
              key: "address1",
              title: "Address",
            },
            {
              key: "rent",
              title: "Rent",
              applyMethod: (row) => {
                return "$" + row.rent.toLocaleString();
              },
            },
            {
              key: "deposit",
              title: "Deposit",
              applyMethod: (row) => {
                return "$" + row.deposit.toLocaleString();
              },
            },
            { key: "status", title: "Status" },
            {
              key: "leaseStartDate",
              title: "Lease From",
              applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
            },
            {
              key: "leaseEndDate",
              title: "Lease To",
              applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
            },
            {
              key: "moveInDate",
              title: "Move In",
              applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
            },
            {
              key: "moveOutDate",
              title: "Move Out",
              applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
            },
            {
              key: "email",
              title: "Email",
            },
            {
              key: "phoneNumber",
              title: "Contact No.",
              applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
            },
          ]}
          fileName="Unit Teanant Directory.csv"
          className="bg-white"
        />
        <ButtonIcon
          id="Download"
          btnName="download"
          dataCy="download"
          title="Download PDF"
          onClick={downloadPDF}
          icon={<BsDownload className="w-5 h-5" />}
          className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
        />
        <Searchbar
          placeholder="Search..."
          data={props.data}
          matchProps={[
            { text: "unitNumber" },
            { text: "residentCode" },
            { text: "name" },
            { text: "address1" },
            { text: "rent" },
            { text: "deposit" },
            { text: "status" },
            { date: "leaseStartDate" },
            { date: "leaseEndDate" },
            { date: "moveInDate" },
            { date: "moveOutDate" },
            { text: "email" },
            { phoneNo: "phoneNumber" },
          ]}
          setTableData={setTableData}
        />
      </div>

      <TableComponent
        tableId="tenantDirectoryReport"
        className={"bg-white"}
        data={tableData}
        columns={[
          { key: "unitNumber", title: "Unit Number", width: "100px" },
          { key: "residentCode", title: "Resident Code", width: "100px" },
          { key: "name", title: "Tenant Name", width: "120px" },
          {
            key: "address1",
            title: "Address",
            //applyMethod: (row) => getFullAddress(row),
            width: "200px",
          },
          {
            key: "rent",
            title: "Rent",
            width: "80px",
            applyMethod: (row) => {
              return "$" + row.rent.toLocaleString();
            },
          },
          {
            key: "deposit",
            title: "Deposit",
            width: "80px",
            applyMethod: (row) => {
              return "$" + row.deposit.toLocaleString();
            },
          },
          { key: "status", title: "Status", width: "80px" },
          {
            key: "leaseStartDate",
            title: "Lease From",
            applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
            width: "100px",
          },
          {
            key: "leaseEndDate",
            title: "Lease To",
            applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
            width: "100px",
          },
          {
            key: "moveInDate",
            title: "Move In",
            width: "100px",
            applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
          },
          {
            key: "moveOutDate",
            title: "Move Out",
            width: "100px",
            applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
          },
          {
            key: "email",
            title: "Email",
            width: "180px",
          },
          {
            key: "phoneNumber",
            title: "Contact No.",
            applyMethod: (row) => PhoneNumFormat(row.phoneNumber),
            width: "120px",
          },
        ]}
        rowOnClick={() => {}}
        //initialSort={{ key: "unitNumber", direction: "descending" }}
      />
    </>
  );
};
