//Common Imports
import React, { useState, useRef, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { useRecoilValue, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { userData, portfolioTreeStore, leaseId } from "../../atoms";
import swal from "sweetalert";
import {
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { IconInCircle } from "../../Widgets/Icons";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { LuFileText } from "react-icons/lu";
import { GetTimeYearToMin } from "../../Widgets/CommonWidgets";
import { BsExclamationOctagon } from "react-icons/bs";

//Pages Import
import CreateEviction from "./Eviction/createEviction";
import AddLeaseAmendment from "./addLeaseAmendment";
import AddRentalTransactions from "./addRentalTransaction";
import AddDemandLetter from "./addDemandLetter";
import CreateVacateNotice from "./VacateNotice/createVacateNotice";
import AddRentalCashTransactions from "./addRentalCashTransaction";

//Reusable Imports
import ExportCSV from "../../Widgets/ExportCSV";
import {
  GetDateInMMDDYYYY,
  clickEffect,
  Searchbar,
  TableComponent,
  Gallery,
} from "../../Widgets/CommonWidgets";
import {
  TextReviewTile,
  MultiDetailsTile,
} from "../../Widgets/Forms/FormReviewTiles";
import InputField, {
  InputPhoneNumField,
  InputTextArea,
} from "../../Widgets/Forms/InputFields";
import {
  ButtonGrayOutline,
  ButtonBlue,
  ButtonCustom,
  ButtonIcon,
} from "../../Widgets/Buttons";
import {
  GetCompleteAddress,
  GetFullAddressinTwoLines,
} from "../../Widgets/Forms/FieldDependants";
import { PDFViewer } from "../Documents/documents";
import { Loader } from "../../Widgets/notificationFeedbacks";

//External Imports
import { Dialog, DialogContent } from "@mui/material";
import { BsPlusCircle, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { TiEdit } from "react-icons/ti";

const TenantDetails = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const userInfo = useRecoilValue(userData);
  const handleFileInput = useRef(null);
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);

  //Tenant Details
  const [tenantInfo, setTenantInfo] = useState("");
  const [tenantEditForm, setTenantEditForm] = useState(false);
  const [tenantImage, setTenantImage] = useState("");

  //Occupant Details
  const [occupantTableData, setOccupantTableData] = useState([]);

  //Rental Transactions
  const [allRentalLedgerRecords, setaAllRentalLedgerRecords] = useState([]);
  const [rentalLedgerTableData, setRentalLedgerTableData] = useState([]);
  const [ledgerTotals, SetLedgerTotals] = useState({
    balance: 0,
    prePayment: 0,
  });
  const [openRentalTransactionsForm, setOpenRentalTransactionsForm] =
    useState(false);
  const [openCashTransactionForm, setOpenCashTransactionsForm] =
    useState(false);

  //Lease Amendments
  const [allLeaseAmendRecords, setAllLeaseAmendRecords] = useState([]);
  const [leaseAmendTableData, setLeaseAmendTableData] = useState([]);
  const [openLeaseAmendForm, setOpenLeaseAmendForm] = useState(false);

  const [openGallery, setOpenGallery] = useState(false);
  const [openAddDemandLetterForm, setOpenAddDemandLetterForm] = useState(false);

  //Delinquency
  const delinquencyTabs = [
    { label: "Demand Letter", nickName: "demandLetter" },
    { label: "Eviction Notice", nickName: "evictionNotice" },
    { label: "Vacate Notice", nickName: "vacateNotice" },
    { label: "Extension", nickName: "extension" },
  ];
  const [activeDelinquencyTab, setActiveDelinquencyTab] = useState(
    portfolioTree.tenant.activeDelinquencyTab || "demandLetter"
  );
  const [demandLettersTableData, setDemandLettersTableData] = useState([]);
  const [allLettersData, setAllLettersData] = useState([]);
  const [activeLeaseId, setActiveLeaseId] = useRecoilState(leaseId);
  const [openPdf, setOpenPdf] = useState(false);
  const [letterLink, setletterlink] = useState("");

  //Eviction Notice
  const [allEvictions, setAllEvictions] = useState([]);
  const [evictionTableData, setEvictionTableData] = useState([]);
  const [openEvictionForm, setOpenEvictionForm] = useState(false);

  //Vacate Notice
  const [openVacateNoticeForm, setOpenVacateNoticeForm] = useState(false);
  const [allVacateNotices, setAllVacateNotices] = useState([]);
  const [vacateNoticeTableData, setVacateNoticeTableData] = useState([]);

  //Extension
  //const [openVacateNoticeForm, setOpenVacateNoticeForm] = useState(false);
  const [allExtensions, setAllExtensions] = useState([]);
  const [extensionTableData, setExtensionTableData] = useState([]);
  const [openViewExtension, setOpenViewExtension] = useState(false);
  const [clickedDetails, setClickedDetails] = useState("");
  const [newLeasePop, setNewLeasePop] = useState(false);
  const [dateIssued, setDateIssued] = useState("");

  //Common
  const tabs = [
    { label: "LEASE INFORMATION", nickName: "leaseInfo" },
    { label: "LEASE AMENDMENTS", nickName: "leaseAmmend" },
    { label: "RENTAL LEDGER", nickName: "rentalLedger" },
    { label: "OCCUPANTS", nickName: "occupants" },
    { label: "NOTICES", nickName: "delinquency" },
    //{ label: "Eviction", nickName: "eviction" },
  ];

  const [activeMenuItem, setActiveMenuItem] = useState(
    portfolioTree.tenant.activeMenuItem || "leaseInfo"
  );
  const [formOperation, setFormOperation] = useState(false);
  const [editableData, setEditableData] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: ``,
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Tenant Details...`,
    });
    // Create an array of promises for each API call
    const promises = [
      getTenantDetails(
        portfolioTree.tenant.unitID,
        portfolioTree.tenant.tenantID
      ),
      getRentalLedgers(portfolioTree.tenant.tenantCode),
      getLeaseAmendmentRecords(
        portfolioTree.tenant.unitID,
        portfolioTree.tenant.tenantID
      ),
      getEvictionNotices(portfolioTree.tenant.tenantCode),
      getAllVacateNotices(portfolioTree.tenant.tenantCode),
      getAllExtensions(portfolioTree.tenant.tenantCode),
    ];

    // Use Promise.all to wait for all promises to resolve
    Promise.all(promises)
      .then(() => {
        // All promises have resolved
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        // Handle errors if any of the promises reject
        setApiProcessing({ loader: false, message: `` });
      });
    getDemandLetters();
  }, []);

  const getTenantDetails = (unitID, tenantID) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Tenant Details...`,
    });
    webApi
      .get(`/reports/tenantDetails/unit/${unitID}/tenant/${tenantID}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          if (res.data.tenant.image !== "") {
            getFileLink(
              res.data.tenant.image,
              "Tenant",
              "Tenant Profile Image"
            );
          }
          setTenantInfo(res.data);
          setOccupantTableData(res.data.occupants);
          setActiveLeaseId(res.data.leaseTerm.ID);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to get the Tenant Details, Please try again later.",
            "error"
          );
      });
  };

  const getFileLink = (prefix, accessor, requestFile) => {
    setApiProcessing({
      loader: true,
      message: `Gathering ${requestFile}...`,
    });
    const data = {
      objectKey: `${prefix}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          if (accessor === "Tenant") {
            setTenantImage(res.data.objectKey);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else swal("Error", `Oops! Unable to get ${requestFile}`, "error");
      });
  };

  const validateFileTypes = (image) => {
    const allowedExtensions = ".png, .jpg, .jpeg"
      .split(",")
      .map((type) => type.trim());

    const fileExtension = image.name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes("." + fileExtension)) {
      UploadTenantImage(image);
    } else {
      swal(
        "Warning",
        "Invalid File type, Please choose a file with one of the (.png, .jpg, .jpeg) file types",
        "warning"
      );
    }
  };

  const UploadTenantImage = (image) => {
    setApiProcessing({
      loader: true,
      message: `Uploading Tenant Profile Image...`,
    });
    webApi
      .post(
        `/tenant/profileImage/${portfolioTree.tenant.tenantID}&${image.name}`
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          const metaData = {
            filename: image.name,
            mediatype: image.type,
          };
          webApi
            .uploadFile(res.data, metaData, image)
            .then((res) => {
              getTenantDetails(
                portfolioTree.tenant.unitID,
                portfolioTree.tenant.tenantID
              );
              swal("Success", "Successfully Updated Tenant Image", "success");
              setApiProcessing({ loader: false, message: `` });
            })
            .catch((error) => {
              setApiProcessing({ loader: false, message: `` });
              if (error.customErrorMessage) {
                swal("Error", error.customErrorMessage, "error");
              } else {
                swal(
                  "Error",
                  "Failed to upload the file. Please try again later.",
                  "error"
                );
              }
            });
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to upload the file. Please try again later.",
            "error"
          );
        }
      });
  };

  const handleEditTenant = (data) => {
    setApiProcessing({
      loader: true,
      message: `Uploading Tenant Details...`,
    });
    webApi
      .put(`/client/tenant/${data.ID}`, data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          const newTenantInfo = {
            ...tenantInfo,
            tenant: res.data,
          };
          setTenantInfo(newTenantInfo);
          swal("Success", "Successfully Updated Tenant", "success");
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to Edit Tenant Details. Please try again later.",
            "error"
          );
        }
      });
  };

  //Rental Transactions
  const getRentalLedgers = (tenantCode) => {
    const requestData = {
      startDate: "",
      endDate: "",
      leaseStatus: portfolioTree.tenant.tenantStatus,
    };
    webApi
      .post("/reports/rentalLedger/tenantCode/" + tenantCode, requestData)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          const sortedData = res.data.ledgers.map((item) => {
            return {
              formatedDate: GetDateInMMDDYYYY(item.dateOccured),
              ...item,
            };
          });

          setRentalLedgerTableData(sortedData);
          setaAllRentalLedgerRecords(sortedData);

          SetLedgerTotals({
            balance: res.data.rentalBalance,
            prePayment: res.data.prepayBalance,
          });
          setApiProcessing({ loader: false, message: `` });
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to get Rental Ledgers of Selected Tenant, Please try again later.`,
            "error"
          );
        }
      });
  };

  //Lease Amendments
  const getLeaseAmendmentRecords = (unitId, tenantID) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Lease Ammedments...`,
    });
    webApi
      .get(`/tenant/leaseAmendments/${tenantID}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllLeaseAmendRecords(res.data);
          setLeaseAmendTableData(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to get the Lease Records, Please try again later.",
            "error"
          );
      });
  };

  const deleteLeaseAmendment = (lease) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete ${
        lease.leaseType
      } Amendment with Effective Date ${GetDateInMMDDYYYY(
        lease.leaseStartDate
      )}?`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setApiProcessing({
          loader: true,
          message: `Removing Selected Lease...`,
        });
        webApi
          .delete(`/leaseAmendments/${lease.ID}`)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              const updatedLeaseAmendRecords = allLeaseAmendRecords.filter(
                (item) => item === lease
              );
              setAllLeaseAmendRecords(updatedLeaseAmendRecords);
              setLeaseAmendTableData(updatedLeaseAmendRecords);
              //getInspectionItems();
              swal("Success", res.data.errorMessage, "success");
            }
            setApiProcessing({ loader: false, message: `` });
          })
          .catch((error) => {
            setApiProcessing({ loader: false, message: `` });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete selected Lease Amendment, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  //Demand Letter API's
  const getDemandLetters = () => {
    setApiProcessing({ loader: true, message: `` });
    webApi
      .get(`/demandLetter/` + portfolioTree.tenant.tenantCode)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setDemandLettersTableData(res.data);
          setAllLettersData(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get Demand Letters! Please try again later.",
            "error"
          );
      });
  };

  const getDemandLetterLink = (letterNumber, action) => {
    setApiProcessing({ loader: true, message: `Loading...` });
    let data = {
      leaseId: activeLeaseId,
      letterNumber: letterNumber,
    };
    webApi
      .post(`/demandLetter/Download/pdf`, data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setletterlink(res.data);
          if (action === "view") {
            setOpenPdf(true);
          } else if (action === "download") {
            window.location.href = res.data;
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to view the Demand Letter, Please try again later.",
            "error"
          );
      });
  };

  const resendDemandLetter = (letterNumber) => {
    setApiProcessing({ loader: true, message: `Loading...` });
    let data = {
      leaseId: activeLeaseId,
      letterNumber: letterNumber,
    };
    webApi
      .post(`/demandLetter/Send/pdf`, data)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          swal("Success", res.data, "success");
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to Resend the Demand Letter, Please try again later.",
            "error"
          );
      });
  };

  const demandLetterTableActions = {
    downloadTitle: "Download",
    downloadOnclick: (row) => {
      getDemandLetterLink(row.letterNumber, "download");
    },
    mailTitle: "Resend",
    mailOnclick: (row) => {
      resendDemandLetter(row.letterNumber);
    },
  };

  //Delinquency
  const delinquencysearchbarProps = {
    demandLetter: {
      placeholder: "Search..",
      data: allLettersData,
      matchProps: [
        { text: "letterNumber" },
        { text: "letterType" },
        { date: "createdAt" },
        { text: "daysOfResponse" },
        { text: "status" },
      ],
      setTableData: setDemandLettersTableData,
      style: "w-64",
    },
    evictionNotice: {
      placeholder: "Search...",
      data: allEvictions,
      matchProps: [
        { text: "evictionId" },
        { text: "caseId" },
        { text: "courtFiledIn" },
        { text: "evictionStatus" },
        { date: "activityUpdateDate" },
        { date: "dateFiled" },
      ],
      setTableData: setEvictionTableData,
    },
    vacateNotice: {
      placeholder: "Search...",
      data: allVacateNotices,
      matchProps: [
        { text: "vacateID" },
        { text: "dateIssued" },
        { text: "moveOutDate" },
        { text: "createdBy" },
        { text: "status" },
      ],
      setTableData: setVacateNoticeTableData,
    },
    extension: {
      placeholder: "Search..",
      data: allExtensions,
      matchProps: [
        { text: "leaseExtensionId" },
        { date: "CreatedAt" },
        { text: "createdBy" },
        { text: "status" },
      ],
      setTableData: setExtensionTableData,
      style: "w-64",
    },
  };

  const delinquencyButtonProps = {
    demandLetter: {
      id: "AddDemandLetter",
      btnName: "AddDemandLetter",
      dataCy: "AddDemandLetter",
      //disabled={tenantInfo.length ? false : true},
      onClick: (e) => {
        //setFormOperation("New");
        setOpenAddDemandLetterForm(true);
      },
    },
    evictionNotice: {
      id: "addEvictionNotice",
      btnName: "addEvictionNotice",
      dataCy: "addEvictionNotice",
      onClick: () => {
        setOpenEvictionForm(true);
      },
    },
    vacateNotice: {
      id: "addVacateNotice",
      btnName: "addVacateNotice",
      dataCy: "addVacateNotice",
      onClick: () => {
        if (
          tenantInfo &&
          tenantInfo.leaseTerm &&
          tenantInfo.leaseTerm.leaseStatus === "Active"
        ) {
          setOpenVacateNoticeForm(true);
        } else
          swal(
            "",
            "There is no active leases. Firstly, please proceed to create a lease!",
            "error"
          );
      },
    },
  };

  //Eviction Notice
  const getEvictionNotices = (tenantCode) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Eviction Notices...`,
    });
    webApi
      .get(`/eviction/tenantCode/${tenantCode}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllEvictions(res.data);
          setEvictionTableData(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to get the Eviction Notices, Please try again later.",
            "error"
          );
      });
  };

  //Vacate Notice
  const getAllVacateNotices = (tenantCode) => {
    webApi
      .get(`/vacateNotice/tenantCode/${tenantCode}`, { loader: true })
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllVacateNotices(res.data);
          setVacateNoticeTableData(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          "Oops! Unable to load Vacate Notices, Please try again later.",
          "error"
        );
      });
  };

  const handleVacateNoticeMail = (VacateNoticeID) => {
    webApi
      .post(`/vacateNotice/sendEmail/${VacateNoticeID}`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else if (res.data.status === true) {
          swal("", res.data.message, "success");
        }
      });
  };

  const VacateNoticeTableActions = {
    //Download
    downloadTitle: "Download",
    downloadDisable: (row) => {
      if (row.document !== "") {
        return false;
      } else return true;
    },
    downloadOnclick: (row) => {
      if (row.document) {
        window.location.href = row.document;
      } else swal("", "Notice to Vacate Does Not Exist!", "error");
    },
    //Main
    mailTitle: "Mail",
    mailDisable: (row) => {
      if (row.document !== "") {
        return false;
      } else return true;
    },
    mailOnclick: (row) => {
      if (row.document) {
        handleExtensionEmail(row.id);
      } else swal("", "Extension Does Not Exist!", "error");
    },
  };

  //Extension
  const getAllExtensions = (tenantCode) => {
    webApi
      .get(`/leaseExtensions/${tenantCode}`, { loader: true })
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllExtensions(res.data);
          setExtensionTableData(res.data);
          const findActiveLease = res.data.filter((item) => {
            setDateIssued(GetDateInMMDDYYYY(item.CreatedAt));
            console.log("came to pop up");
            if (item.status === "Accepted") {
              setNewLeasePop(true);
            }
          });
        }
      })
      .catch((error) => {
        swal(
          "Error",
          "Oops! Unable to load Lease Extensions, Please try again later.",
          "error"
        );
      });
  };

  const ExtensionTableActions = {
    mailOnclick: (row) => {
      handleExtensionEmail(row.ID);
    },
  };

  const handleExtensionRowClick = (row) => {
    setOpenViewExtension(true);
    setClickedDetails(row.ID);
    if (row.isOpened === false) {
      updateOpenedExtensionBackend(row.ID);
    }
  };

  const updateOpenedExtensionBackend = (extensionId) => {
    webApi
      .put(`/leaseExtension/openedOn/${extensionId}`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Error", res.data.errorMessage, "error");
        } else if (res.data.status === true) {
          console.log("Success", res.data.message, "success");
        }
      });
  };

  const handleExtensionEmail = (extensionId) => {
    webApi
      .post(`/leaseExtension/sendEmail/${userInfo.ID}/${extensionId}`, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else if (res.data.status === true) {
          swal("", res.data.message, "success");
        }
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {openGallery && (
        <Gallery
          images={[tenantImage]}
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
        />
      )}

      {tenantEditForm && (
        <EditTenant
          trigger={tenantEditForm}
          setTrigger={setTenantEditForm}
          tenant={tenantInfo.tenant}
          handleSubmit={(data) => handleEditTenant(data)}
        />
      )}

      {openLeaseAmendForm && (
        <AddLeaseAmendment
          trigger={openLeaseAmendForm}
          setTrigger={setOpenLeaseAmendForm}
          leaseId={tenantInfo && tenantInfo.leaseTerm.ID}
          rent={tenantInfo && tenantInfo.leaseTerm.monthlyRent}
          endDate={tenantInfo && tenantInfo.leaseTerm.leaseEndDate}
          operation={formOperation}
          editData={editableData}
          getAllLeaseAmendments={(data) => {
            getLeaseAmendmentRecords(
              portfolioTree.tenant.unitID,
              portfolioTree.tenant.tenantID
            );
            setEditableData("");
          }}
        />
      )}

      {openRentalTransactionsForm && (
        <AddRentalTransactions
          trigger={openRentalTransactionsForm}
          setTrigger={setOpenRentalTransactionsForm}
          tennatCode={portfolioTree.tenant.tenantCode}
          unitID={portfolioTree.tenant.unitID}
          getAllRentalTransactions={(data) =>
            getRentalLedgers(portfolioTree.tenant.tenantCode)
          }
        />
      )}

      {openCashTransactionForm && (
        <AddRentalCashTransactions
          trigger={openCashTransactionForm}
          setTrigger={setOpenCashTransactionsForm}
          tennatCode={portfolioTree.tenant.tenantCode}
          unitID={portfolioTree.tenant.unitID}
          getAllRentalTransactions={(data) =>
            getRentalLedgers(portfolioTree.tenant.tenantCode)
          }
        />
      )}

      {openAddDemandLetterForm && (
        <AddDemandLetter
          trigger={openAddDemandLetterForm}
          setTrigger={setOpenAddDemandLetterForm}
          getLetters={getDemandLetters}
          name={tenantInfo.tenant.firstName + " " + tenantInfo.tenant.lastName}
          address={tenantInfo.unit.address}
          balance={ledgerTotals.balance}
        />
      )}

      {openEvictionForm && (
        <CreateEviction
          trigger={openEvictionForm}
          setTrigger={setOpenEvictionForm}
          tenantName={
            tenantInfo &&
            tenantInfo.tenant.firstName + " " + tenantInfo.tenant.lastName
          }
          tenantCode={portfolioTree.tenant.tenantCode}
          propAddress={
            tenantInfo && GetCompleteAddress(tenantInfo.unit.address)
          }
          handleEvictionCallback={(data) =>
            getEvictionNotices(portfolioTree.tenant.tenantCode)
          }
        />
      )}

      {openVacateNoticeForm && (
        <CreateVacateNotice
          trigger={openVacateNoticeForm}
          setTrigger={setOpenVacateNoticeForm}
          leaseDetails={tenantInfo && tenantInfo.leaseTerm}
          getAllVacateNotices={(data) =>
            getAllVacateNotices(portfolioTree.tenant.tenantCode)
          }
        />
      )}

      {openPdf && (
        <PDFViewer
          pdfUrl={letterLink}
          onClose={(e) => {
            setOpenPdf(false);
          }}
        />
      )}

      {openViewExtension && (
        <ExtensionDetails
          trigger={openViewExtension}
          setTrigger={setOpenViewExtension}
          data={clickedDetails}
          user={userInfo}
          getAllExtension={() =>
            getAllExtensions(portfolioTree.tenant.tenantCode)
          }
        />
      )}

      <div className="w-11/12 m-auto border">
        <section
          name="Tenant details page title and Edit Option"
          className="flex justify-between items-center bg-blue-30 h-10 mb-4 rounded px-2"
        >
          <span className="flex gap-4">
            <BsFillArrowLeftCircleFill
              id="backToPrevious"
              name="backToPrevious"
              data-cy="backToPrevious"
              title="Back to Previous page"
              onClick={(e) => {
                SetPortfolioTree({
                  ...portfolioTree,
                  tenant: {},
                });
                navigate(-1);
              }}
              className="text-blue-52 w-6 h-6 cursor-pointer"
            />
            {tenantInfo && (
              <span className="flex font-normal items-center">
                &nbsp;
                {tenantInfo.tenant.firstName + " " + tenantInfo.tenant.lastName}
              </span>
            )}
          </span>
          {tenantInfo && tenantInfo.tenant && (
            <ButtonIcon
              id="editTenant"
              btnName="editTenant"
              dataCy="editTenant"
              title="Edit Tenant"
              onClick={(e) => {
                setTenantEditForm(true);
              }}
              icon={<TiEdit title="Edit Tenant" className={`w-6 h-6`} />}
              className="hover:!bg-white hover:!text-blue-52"
            />
          )}
        </section>

        <section
          name="tenantDetails"
          className="flex justify-around items-start mx-6 my-6 gap-[3%] md:overflow-x-auto"
        >
          <div className="w-96 h-52 border flex flex-col justify-center items-center bg-gray-50 overflow-hidden">
            {tenantInfo && tenantInfo.tenant.image !== "" && tenantImage ? (
              <img
                id="tenantImage"
                src={tenantImage}
                alt="Tenant Image"
                onClick={(e) => {
                  setOpenGallery(true);
                }}
                className="w-full h-full object-cover cursor-pointer"
              />
            ) : (
              <>
                <p className="text-gray-600 text-center">
                  No Tenant Image to Display
                </p>
                <input
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  className="hidden"
                  ref={handleFileInput}
                  onChange={(e) => validateFileTypes(e.target.files[0])}
                />
                <button
                  id="uploadImage"
                  name="uploadImage"
                  data-cy="uploadImage"
                  onClick={() => handleFileInput.current.click()}
                  className={`text-blue-54 mt-2 p-1 rounded-md border border-blue-54 shadow hover:bg-blue-54 hover:text-white ${clickEffect}`}
                >
                  Upload Image
                </button>
              </>
            )}
          </div>

          <MultiDetailsTile
            details={{
              "Tenant Status": tenantInfo && tenantInfo.tenant.tenantStatus,
              "First Name": tenantInfo && tenantInfo.tenant.firstName,
              "Last Name": tenantInfo && tenantInfo.tenant.lastName,
              "Contact No.": tenantInfo && tenantInfo.tenant.phoneNumber,
              Email: tenantInfo && tenantInfo.tenant.email,
              Address:
                tenantInfo && GetFullAddressinTwoLines(tenantInfo.unit.address),
            }}
            labelClassName="!w-28 text-gray-500 mb-2"
          />

          <MultiDetailsTile
            details={{
              "Lease Status": tenantInfo && tenantInfo.leaseTerm.leaseStatus,
              "Lease Effective Date": GetDateInMMDDYYYY(
                tenantInfo && tenantInfo.leaseTerm.leaseStartDate
              ),
              "Lease End Date": GetDateInMMDDYYYY(
                tenantInfo && tenantInfo.leaseTerm.leaseEndDate
              ),
              "Move In Date": GetDateInMMDDYYYY(
                tenantInfo && tenantInfo.leaseTerm.moveInDate
              ),
              "Move Out Date": GetDateInMMDDYYYY(
                tenantInfo && tenantInfo.leaseTerm.moveOutDate
              ),
              "Lease Duration":
                tenantInfo && tenantInfo.leaseTerm.leaseDuration,
            }}
            labelClassName="!w-36 text-gray-500 mb-2"
          />
        </section>

        <section
          name="tabs"
          className="flex justify-start items-center h-10 font-semibold shadow text-gray-600 cursor-pointer border-t-2 border-blue-52"
        >
          {tabs.map((tab, index) => (
            <p
              key={index}
              id={tab.nickName}
              data-cy={tab.nickName}
              className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-fit text-center mx-6 ${
                activeMenuItem === tab.nickName
                  ? `text-[#003C9A] border-[#003C9A] font-semibold w-fit border-b-2`
                  : `text-gray-600 border-gray-600`
              } 
                `}
              onClick={(e) => setActiveMenuItem(tab.nickName)}
            >
              {tab.label}
            </p>
          ))}
        </section>

        {activeMenuItem === "leaseInfo" && (
          <section name="Rental details">
            <div className="grid grid-cols-5 grid-rows-2 gap-y-6 mb-4 py-4 ml-4">
              <TextReviewTile
                title="Security Deposit"
                body={
                  tenantInfo &&
                  "$" + tenantInfo.leaseTerm.securityDeposit.toLocaleString()
                }
              />
              <TextReviewTile
                title="Monthly Rent"
                body={
                  tenantInfo &&
                  "$" + tenantInfo.leaseTerm.monthlyRent.toLocaleString()
                }
              />
              <TextReviewTile
                title="Late Fee"
                body={
                  tenantInfo &&
                  "$" + tenantInfo.leaseTerm.lateFee.toLocaleString()
                }
              />
              <TextReviewTile
                title="No of Due Days"
                body={tenantInfo && tenantInfo.leaseTerm.dueDay}
              />
              <TextReviewTile
                title="Rentable Items"
                body={tenantInfo && tenantInfo.leaseTerm.rentableItems}
              />
              <TextReviewTile
                title="Pets Allowed"
                body={
                  tenantInfo && tenantInfo.leaseTerm.petsAllowed ? "Yes" : "No"
                }
              />
              {tenantInfo && tenantInfo.leaseTerm.petsAllowed && (
                <>
                  <TextReviewTile
                    title="No Of Pets"
                    body={tenantInfo && tenantInfo.leaseTerm.numberOfPets}
                  />
                  <TextReviewTile
                    title="Type Of Pets"
                    body={tenantInfo && tenantInfo.leaseTerm.typeOfPets}
                  />
                  <TextReviewTile
                    title="Pet Deposit"
                    body={
                      tenantInfo &&
                      "$" + tenantInfo.leaseTerm.petDeposite.toLocaleString()
                    }
                  />
                  <TextReviewTile
                    title="Pet Fee"
                    body={
                      tenantInfo &&
                      "$" + tenantInfo.leaseTerm.petFee.toLocaleString()
                    }
                  />
                </>
              )}
            </div>
          </section>
        )}

        {activeMenuItem === "occupants" && (
          <section name="occupants Table and Options">
            <div className="flex justify-between items-end gap-4 m-4">
              <p className="font-semibold">
                Occupants ({tenantInfo && tenantInfo.occupants.length})
              </p>

              <Searchbar
                placeholder="Search..."
                data={tenantInfo && tenantInfo.occupants}
                matchProps={[
                  { text: "firstName" },
                  { text: "lastName" },
                  { text: "age" },
                  { date: "email" },
                  { date: "phoneNumber" },
                ]}
                setTableData={setOccupantTableData}
              />
            </div>

            <TableComponent
              tableId="Occupants Table"
              data={occupantTableData}
              columns={[
                {
                  key: "firstName",
                  title: "First Name",
                },
                {
                  key: "lastName",
                  title: "Last Name",
                },
                {
                  key: "age",
                  title: "Age",
                },
                {
                  key: "email",
                  title: "Email",
                },
                {
                  key: "phoneNumber",
                  title: "Contact Number",
                },
              ]}
              rowOnClick={(row) => {}}
              //initialSort={{ key: "age", direction: "descending" }}
            />
          </section>
        )}

        {activeMenuItem === "rentalLedger" && (
          <section name="Rental Ledger Table and Options">
            <span className="flex justify-between items-end gap-4 m-4">
              <span className="flex items-end justify-around gap-4 md:flex-col">
                <p className="font-semibold whitespace-nowrap">
                  Rental Transactions ({allRentalLedgerRecords.length})
                </p>

                <span className="text-gray-400/100 flex items-center gap-1">
                  <span className="inline-block w-2 h-2 rounded-full bg-green-500 mr-2"></span>
                  Prepayment
                  <p className="font-semibold text-black text-xl">
                    {ledgerTotals.prePayment.toString().startsWith("-")
                      ? "-$" +
                        ledgerTotals.prePayment.toLocaleString().substring(1)
                      : "$" + ledgerTotals.prePayment.toLocaleString()}
                  </p>
                </span>

                <span className="text-gray-400/100 flex items-center gap-1">
                  <span className="inline-block w-2 h-2 rounded-full bg-red-500 mr-2"></span>
                  Balance
                  <p className="font-semibold text-black text-xl">
                    {ledgerTotals.balance.toString().startsWith("-")
                      ? "-$" +
                        ledgerTotals.balance.toLocaleString().substring(1)
                      : "$" + ledgerTotals.balance.toLocaleString()}
                  </p>
                </span>
              </span>

              <span className="flex justify-between items-center gap-2">
                <ButtonCustom
                  btnName="addNewTransaction"
                  dataCy="addNewTransaction"
                  id="addNewTransaction"
                  //disabled={}
                  name={
                    <>
                      <BsPlusCircle className="w-5 h-5 mr-2" /> Add Charge
                    </>
                  }
                  onClick={() => {
                    setOpenRentalTransactionsForm(true);
                  }}
                  className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52  hover:bg-blue-52 hover:text-white`}
                />

                <ButtonCustom
                  btnName="addNewPayment"
                  dataCy="addNewPayment"
                  id="addNewPayment"
                  //disabled={}
                  name={
                    <>
                      <BsPlusCircle className="w-5 h-5 mr-2" /> Add Payment
                    </>
                  }
                  onClick={() => {
                    setOpenCashTransactionsForm(true);
                  }}
                  className={`flex justify-center items-center w-36 h-10 rounded-md text-blue-52 border-blue-52  hover:bg-blue-52 hover:text-white`}
                />

                <ExportCSV
                  data={rentalLedgerTableData}
                  keys={[
                    {
                      key: "formatedDate",
                      title: "Date",
                      //applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
                    },
                    { key: "description", title: "Description" },
                    {
                      key: "paymentInfo",
                      title: "Payment Type",
                      getCellStyles: (row) => {
                        if (row.paymentInfo === "Charge") {
                          return { color: "red" };
                        }
                      },
                    },
                    {
                      key: "paymentAmount",
                      title: "Amount",
                      applyMethod: (row) => {
                        if (row.paymentInfo !== "Charge" && row.paymentAmount) {
                          return "-$" + row.paymentAmount;
                        } else {
                          return "$" + row.paymentAmount;
                        }
                      },
                      getCellStyles: (row) => {
                        if (row.paymentInfo === "Charge") {
                          return { color: "red" };
                        }
                      },
                    },

                    {
                      key: "balance",
                      title: "Balance",
                      applyMethod: (row) => {
                        if (row.paymentInfo === "Prepayment") {
                          return `-`;
                        } else {
                          if (row.balance.toString().startsWith("-")) {
                            return "-$" + row.balance.substring(1);
                          } else {
                            return "$" + row.balance;
                          }
                        }
                      },
                    },
                  ]}
                  fileName="Rental Ledger.csv"
                />

                <Searchbar
                  placeholder="Search Ledger Records..."
                  data={allRentalLedgerRecords}
                  matchProps={[
                    { text: "formatedDate" },
                    { text: "description" },
                    { text: "paymentAmount" },
                    { text: "paymentInfo" },
                    { text: "balance" },
                  ]}
                  setTableData={setRentalLedgerTableData}
                  style="!w-auto"
                />
              </span>
            </span>

            <TableComponent
              tableId="Ledger Table"
              data={rentalLedgerTableData}
              columns={[
                {
                  key: "formatedDate",
                  title: "Date",
                  //applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
                },
                { key: "description", title: "Description" },
                {
                  key: "paymentInfo",
                  title: "Payment Type",
                  getCellStyles: (row) => {
                    if (row.paymentInfo === "Charge") {
                      return { color: "red" };
                    }
                  },
                },
                {
                  key: "paymentAmount",
                  title: "Amount",
                  applyMethod: (row) => {
                    if (row.paymentInfo !== "Charge" && row.paymentAmount) {
                      return "-$" + row.paymentAmount.toLocaleString();
                    } else {
                      return "$" + row.paymentAmount.toLocaleString();
                    }
                  },
                  getCellStyles: (row) => {
                    if (row.paymentInfo === "Charge") {
                      return { color: "red" };
                    }
                  },
                },

                {
                  key: "balance",
                  title: "Balance",
                  applyMethod: (row) => {
                    if (row.paymentInfo === "Prepayment") {
                      return `-`;
                    } else {
                      if (row.balance.toString().startsWith("-")) {
                        return "-$" + row.balance.toLocaleString().substring(1);
                      } else {
                        return "$" + row.balance.toLocaleString();
                      }
                    }
                  },
                },
              ]}
              rowOnClick={(row) => {}}
              //initialSort={{ key: "formatedDate", direction: "descending" }}
            />
          </section>
        )}

        {activeMenuItem === "leaseAmmend" && (
          <section name="lease Amendment Table and Options">
            <div className="flex justify-between items-end gap-4 m-4">
              <p className="font-semibold">
                Lease Amendments ({allLeaseAmendRecords.length})
              </p>

              <span className="flex justify-between items-center gap-2">
                <ButtonCustom
                  id="addNewLeaseAmend"
                  btnName="addNewLeaseAmend"
                  dataCy="addNewLeaseAmend"
                  name={
                    <>
                      <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                    </>
                  }
                  //disabled={tenantInfo.length ? false : true}
                  onClick={(e) => {
                    setFormOperation("New");
                    setOpenLeaseAmendForm(true);
                  }}
                  className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52  hover:bg-blue-52 hover:text-white`}
                />

                <Searchbar
                  placeholder="Search..."
                  data={allLeaseAmendRecords}
                  matchProps={[
                    { text: "leaseType" },
                    { text: "rent" },
                    { text: "deposit" },
                    { date: "leaseStartDate" },
                    { date: "leaseEndDate" },
                    // { date: "CreatedAt" },
                    // { text: "createdByName" },
                    { text: "leaseAmendmentStatus" },
                  ]}
                  setTableData={setLeaseAmendTableData}
                />
              </span>
            </div>

            <TableComponent
              tableId="Lease Amend Table"
              data={leaseAmendTableData}
              columns={[
                {
                  key: "leaseType",
                  title: "Lease Type",
                  // width: 100,
                },
                {
                  key: "rent",
                  title: "Rent",
                  applyMethod: (row) => "$" + row.rent.toLocaleString(),
                  // width: 100,
                },
                {
                  key: "deposit",
                  title: "Deposit",
                  applyMethod: (row) => {
                    if (
                      row.leaseType === "Extension" ||
                      row.leaseType === "Renewal"
                    ) {
                      return "-";
                    } else {
                      return "$" + row.deposit.toLocaleString();
                    }
                  },
                  // width: 100,
                },
                {
                  key: "leaseStartDate",
                  title: "Lease Effective Date",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
                  // width: 100,
                },
                {
                  key: "leaseEndDate",
                  title: "Lease End Date",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
                  // width: 100,
                },

                // {
                //   key: "CreatedAt",
                //   title: "Created On",
                //   applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                //   // width: 120,
                // },

                // {
                //   key: "createdByName",
                //   title: "Created By",
                // },

                {
                  key: "leaseAmendmentStatus",
                  title: "Status",
                  // width: 100,
                  getCellStyles: (row) => {
                    return { color: row.statusColor };
                  },
                },
              ]}
              //actions={leaseAmendmentTableActions}
              rowOnClick={(row) =>
                navigate("/tenants/leaseDetails", {
                  state: { data: { ...row } },
                })
              }
              initialSort={{ key: "CreatedAt", direction: "descending" }}
            />
          </section>
        )}

        {activeMenuItem === "delinquency" && (
          <>
            <div className="flex items-end gap-4 mt-4">
              <section
                name="tabs"
                className="flex justify-between items-center h-10 font-semibold text-gray-600 cursor-pointer w-full"
              >
                <div className="flex items-center">
                  {delinquencyTabs.map((tab, index) => (
                    <p
                      key={index}
                      id={tab.nickName}
                      data-cy={tab.nickName}
                      className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-fit text-center mx-6  ${
                        activeDelinquencyTab === tab.nickName
                          ? `text-[#003C9A] border-[#003C9A] font-semibold w-fit border-b-2`
                          : `text-gray-600 border-gray-600`
                      } 
                `}
                      onClick={(e) => setActiveDelinquencyTab(tab.nickName)}
                    >
                      {tab.label}
                    </p>
                  ))}
                </div>

                <span className="flex items-center gap-2 mb-2">
                  {activeDelinquencyTab !== "extension" && (
                    <ButtonCustom
                      {...delinquencyButtonProps[activeDelinquencyTab]}
                      name={
                        <>
                          <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                        </>
                      }
                      className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 hover:bg-blue-52 hover:text-white`}
                    />
                  )}

                  <Searchbar
                    {...delinquencysearchbarProps[activeDelinquencyTab]}
                  />
                </span>
              </section>
            </div>

            {activeDelinquencyTab === "demandLetter" && (
              <TableComponent
                tableId="Demand Letter Table"
                data={demandLettersTableData}
                columns={[
                  {
                    key: "letterNumber",
                    title: "Letter Number",
                  },
                  {
                    key: "letterType",
                    title: "Letter Type",
                  },
                  {
                    key: "createdAt",
                    title: "Date Issued",
                    applyMethod: (row) => {
                      return GetDateInMMDDYYYY(row.createdAt);
                    },
                  },
                  {
                    key: "daysOfResponse",
                    title: "Days To Response",
                  },
                  {
                    key: "lastOpenedAt",
                    title: "Opened On",
                    applyMethod: (row) => {
                      return GetDateInMMDDYYYY(row.lastOpenedAt);
                    },
                  },
                  {
                    key: "status",
                    title: "Status",
                  },
                ]}
                actions={demandLetterTableActions}
                rowOnClick={(row) => {
                  getDemandLetterLink(row.letterNumber, "view");
                }}
                initialSort={{ key: "createdAt", direction: "descending" }}
              />
            )}

            {activeDelinquencyTab === "evictionNotice" && (
              <TableComponent
                tableId="Eviction Notice Table"
                data={evictionTableData}
                columns={[
                  {
                    key: "evictionId",
                    title: "Eviction ID",
                  },
                  {
                    key: "caseId",
                    title: "Case ID",
                  },
                  {
                    key: "courtFiledIn",
                    title: "Court Filed In",
                  },
                  {
                    key: "dateFiled",
                    title: "Date Filed",
                    applyMethod: (row) => {
                      return GetDateInMMDDYYYY(row.dateFiled);
                    },
                  },
                  {
                    key: "evictionStatus",
                    title: "Status",
                    getCellStyles: (row) => {
                      if (row.evictionStatus === "Tenant Evicted") {
                        return { color: "red" };
                      }
                    },
                  },
                ]}
                rowOnClick={(row) => {
                  navigate("/tenants/evictionDetails", {
                    state: {
                      data: {
                        ...row,
                        tenantCode: portfolioTree.tenant.tenantCode,
                      },
                    },
                  });
                }}
              />
            )}

            {activeDelinquencyTab === "vacateNotice" && (
              <TableComponent
                tableId="Vacate Notice Table"
                data={vacateNoticeTableData}
                columns={[
                  {
                    key: "vacateID",
                    title: "Vacate ID",
                  },
                  {
                    key: "dateIssued",
                    title: "Date Issued",
                    applyMethod: (row) => {
                      return GetDateInMMDDYYYY(row.dateIssued);
                    },
                  },
                  {
                    key: "moveOutDate",
                    title: "Move Out Date",
                    applyMethod: (row) => {
                      return GetDateInMMDDYYYY(row.moveOutDate);
                    },
                  },
                  {
                    key: "createdBy",
                    title: "Created By",
                  },
                  {
                    key: "status",
                    title: "Status",
                  },
                ]}
                actions={VacateNoticeTableActions}
                rowOnClick={(row) => {
                  navigate("/tenants/vacateNoticeDetails", {
                    state: {
                      vacateNotice: { ...row },
                      leaseDetails: { ...(tenantInfo && tenantInfo.leaseTerm) },
                    },
                  });
                }}
              />
            )}

            {activeDelinquencyTab === "extension" && (
              <TableComponent
                tableId="Extension Table"
                data={extensionTableData}
                columns={[
                  {
                    key: "leaseExtensionId",
                    title: "Extension ID",
                  },
                  {
                    key: "CreatedAt",
                    title: "Date Issued",
                    applyMethod: (row) => {
                      return GetDateInMMDDYYYY(row.CreatedAt);
                    },
                  },
                  {
                    key: "createdBy",
                    title: "Created By",
                  },
                  {
                    key: "status",
                    title: "Status",
                  },
                ]}
                actions={ExtensionTableActions}
                rowOnClick={(row) => {
                  handleExtensionRowClick(row);
                }}
                initialSort={{ key: "CreatedAt", direction: "descending" }}
              />
            )}
          </>
        )}

        {activeDelinquencyTab === "extension" && newLeasePop && (
          <>
            <Dialog
              open={true} // Set the condition for opening the dialog
              aria-labelledby="view-lease-form" // Ensure the ID is unique and follows a standard naming convention
              fullWidth
              maxWidth="sm"
              PaperProps={{ sx: { height: "auto" } }}
              scroll="body"
              className="flex flex-col justify-center w-full"
            >
              <div className="flex mb-2 bg-blue-52 text-white font-semibold rounded-sm h-10">
                <div className="flex items-center justify-center flex-grow">
                  Notification
                </div>
                <div className="flex items-center justify-end pr-2 text-xl cursor-pointer">
                  <ButtonIcon
                    id="close"
                    btnName="close"
                    dataCy="close"
                    title="Close"
                    onClick={(e) => setNewLeasePop(false)} // Ensure setNewLeasePop is defined in the parent component
                    icon={<AiOutlineCloseCircle className="w-5 h-5" />}
                    className="hover:!text-red-600"
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center m-6 gap-3">
                <AiOutlineExclamationCircle className="w-12 h-12 text-blue-52" />
                <div className="flex flex-col text-lg text-center">
                  <span>Lease Extension Requested by</span>
                  <span>
                    Tenant: {portfolioTree.tenant.firstName}{" "}
                    {portfolioTree.tenant.lastName} on {dateIssued}
                  </span>
                  <span>Check Tenant profile for details</span>
                </div>
              </div>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default TenantDetails;

//Edit Tenant Details Page
const EditTenant = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tenantStatus, setTenantStatus] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {
    setTenantDetails();
  }, []);

  const setTenantDetails = () => {
    setFirstName(props.tenant.firstName);
    setLastName(props.tenant.lastName);
    setTenantStatus(props.tenant.tenantStatus);
    setContactNo(props.tenant.phoneNumber);
    setEmail(props.tenant.email);
  };

  const Submit = (e) => {
    e.preventDefault();
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!firstName.trim()) {
      errors.firstName = "First Name is required!";
    }
    if (!lastName.trim()) {
      errors.lastName = "Last Name is required!";
    }
    if (!tenantStatus) {
      errors.tenantStatus = "Status is required!";
    }
    if (!contactNo || contactNo.length !== 12) {
      errors.phoneNum = "Contact number is required!";
    }
    if (!email) {
      errors.email = "Email is required!";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is not valid!";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    } else {
      const data = {
        ID: props.tenant.ID,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: contactNo,
        tenantStatus: tenantStatus,
        email: email,
      };
      props.handleSubmit(data);
      props.setTrigger(false);
    }
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    setTenantDetails(props.tenant);
    props.setTrigger(false);
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Edit Tenant"
        fullWidth
        maxWidth="sm"
        //PaperProps={{ sx: { height: "100%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-full h-screen"
      >
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          Edit Tenant
        </span>

        <DialogContent dividers>
          <section className="flex flex-col justify-center items-center gap-3">
            <InputField
              label="First Name"
              name="firstName"
              id="firstName"
              dataCy="firstName"
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              errorText={formErrors.firstName}
              style="w-96"
            />

            <InputField
              label="Last Name"
              name="lastName"
              id="lastName"
              dataCy="lastName"
              type="text"
              placeholder="Enter Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              errorText={formErrors.lastName}
              style="w-96"
            />

            <InputPhoneNumField
              label="Contact Number"
              name="contactNo"
              id="contactNo"
              dataCy="contactNo"
              placeholder="Enter Contact Number"
              value={contactNo}
              setValue={setContactNo}
              errorText={formErrors.contactNo}
              style="w-96"
            />

            <InputField
              label="Email"
              name="email"
              id="email"
              dataCy="email"
              type="text"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              errorText={formErrors.email}
              style="w-96"
            />
          </section>
        </DialogContent>

        <div className="flex gap-4 items-center justify-center my-4">
          <ButtonGrayOutline
            id="cancel"
            btnName="cancel"
            dataCy="cancel"
            name="Cancel"
            onClick={handleCancelButton}
            className="justify-self-end"
          />
          <ButtonBlue
            name="Submit"
            id="submit"
            btnName="submit"
            dataCy="submit"
            onClick={Submit}
            className=""
          />
        </div>
      </Dialog>
    </>
  );
};

const ExtensionDetails = (props) => {
  let webApi = new WebApimanager();
  const [activeMenuItem, setActiveMenuItem] = useState("notes");
  const tabs = [{ label: "Notes", nickName: "notes" }];
  const [formErrors, setFormErrors] = useState("");
  const [notes, setNotes] = useState([]);
  const [notesMode, setNotesMode] = useState(false);
  const [enteredNote, setEnteredNote] = useState("");
  const [userResponse, setUserResponse] = useState("");
  const [lease, setLease] = useState([]);

  useEffect(() => {
    getExtensionDetails(props.data);
  }, []);

  const getExtensionDetails = (id) => {
    webApi
      .get(`/leaseExtension/${id}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (res.data.leaseExtension) {
            setLease(res.data.leaseExtension);
          } else {
            swal("Error", "Lease Extension details not found!", "error");
          }

          if (res.data.notes && res.data.notes.length > 0) {
            setNotes(res.data.notes); // Safely set notes if available
          } else {
            setNotes([]); // Empty array if no notes
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, Unable to get Extension Details! Please try again later.",
            "error"
          );
        }
      });
  };

  const validate = () => {
    const errors = {};

    if (notesMode && !enteredNote) {
      errors.enteredNote = "Notes is required!";
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);

    if (Object.keys(temp).length) {
      return;
    } else {
      let data = {
        clientId: props.user.ID,
        status: userResponse,
        note: enteredNote,
      };
      //console.log(data,"createdata")
      webApi
        .put(`/leaseExtension/${props.data}`, data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            swal("Success", res.data.message, "success");
            props.setTrigger(false);
            props.getAllExtension();
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to update, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="View Extension Details"
        fullWidth
        maxWidth={notesMode ? "xs" : "md"}
        //PaperProps={{ sx: { height: "50%" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            Lease Extension{notesMode ? " Request" : ""}
          </span>
          <ButtonIcon
            id="close"
            btnName="close"
            dataCy="close"
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        {notesMode === false ? (
          <section className="m-4">
            <div
              name="Info"
              className="flex justify-start items-center gap-2 mb-4"
            >
              <IconInCircle
                icon={<LuFileText className="w-8 h-8 text-black" />}
                radius="4rem"
                color="#add8e6"
              />
              <span className="gap-1">
                <p
                  className=" font-normal uppercase"
                  style={{ color: "#1e90ff" }}
                >
                  {lease.letterType || "-"}
                </p>

                <DetailsTile
                  label="Status"
                  data={lease.status || "-"}
                  dataClassName="font-bold"
                  labelClassName="text-gray-500"
                  className="gap-4"
                />

                <DetailsTile
                  label="Lease Extension Type"
                  data={"Month to Month"}
                  dataClassName="font-bold"
                  labelClassName="text-gray-500"
                  className="gap-4"
                />
              </span>
            </div>

            <section
              name="tabs"
              className="flex justify-start items-center h-10 font-medium text-gray-600 cursor-pointer mb-4"
            >
              {tabs.map((tab, index) => (
                <p
                  key={index}
                  id={tab.nickName}
                  name={tab.nickName}
                  data-cy={tab.nickName}
                  className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 border-b-2 w-44 text-center ${
                    activeMenuItem === tab.nickName
                      ? `text-[#003C9A] border-[#003C9A] font-semibold`
                      : `text-gray-600 border-gray-600`
                  }`}
                  onClick={(e) => setActiveMenuItem(tab.nickName)}
                >
                  {tab.label}
                </p>
              ))}
            </section>

            {activeMenuItem === "notes" && (
              <>
                <div
                  name="NotesViewScreen"
                  id="NotesViewScreen"
                  className="overflow-y-auto overscroll-contain max-h-96 w-full"
                >
                  {notes && notes.length > 0 ? (
                    notes.map((note, index) => (
                      <span
                        key={index}
                        className="flex justify-center items-center gap-4 mb-4"
                      >
                        <IconInCircle
                          icon={note.creatorRole[0] || "X"}
                          radius={"2rem"}
                          color={
                            note.creatorRole === "Tenant"
                              ? "#3661A9"
                              : "#0A875C"
                          }
                          className="text-white text-lg"
                        />
                        <span className="flex flex-col w-[90%] text-base">
                          <span className="flex justify-between items-center text-sm">
                            <p className="font-normal">
                              {note.creatorRole + ": " + note.creatorName}
                            </p>
                            <p className="text-[#BDBABA] ml-auto">
                              {GetTimeYearToMin(note.UpdatedAt)}
                            </p>
                          </span>
                          <p className="font-light bg-[#E8EAF6] rounded p-2 break-words">
                            {note.note}
                          </p>
                        </span>
                      </span>
                    ))
                  ) : (
                    <p className="text-center text-gray-500">
                      No data to show!
                    </p>
                  )}
                </div>
                <div
                  className={`flex justify-center gap-36 items-center m-2 mb-4 ${
                    lease.status === "Requested" ? `block` : `hidden`
                  }`}
                >
                  <ButtonBlue
                    id="reject"
                    btnName="reject"
                    dataCy="reject"
                    name="Reject"
                    onClick={() => {
                      setUserResponse("Reject");
                      setNotesMode(true);
                    }}
                    className={` mt-3 h-8 cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white hover:bg-blue-52 focus:text-white`}
                  />

                  <ButtonBlue
                    id="accept"
                    btnName="accept"
                    dataCy="accept"
                    name="Accept"
                    onClick={() => {
                      setUserResponse("Accept");
                      setNotesMode(true);
                    }}
                    className={`mt-3 h-8 cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white hover:bg-blue-52 focus:text-white`}
                  />
                </div>
              </>
            )}
          </section>
        ) : (
          <section className="m-4">
            <div className="flex justify-center">
              <BsExclamationOctagon className="w-8 h-8" />
            </div>
            <InputTextArea
              label="Note"
              name="note"
              id="note"
              dataCy="note"
              type="text"
              placeholder={`Enter notes for ${userResponse}...`}
              value={enteredNote}
              onChange={(e) => setEnteredNote(e.target.value)}
              errorText={formErrors.enteredNote}
              style="!w-full"
              className="h-20 rounded-lg"
            />

            <div className="flex justify-center gap-36 items-center m-2 mb-4">
              <ButtonBlue
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={() => {
                  props.setTrigger(false);
                }}
                className={` mt-3 h-8 cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white hover:bg-blue-52 focus:text-white`}
              />

              <ButtonBlue
                id="submit"
                btnName="submit"
                dataCy="submit"
                name="Submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className={`mt-3 h-8 cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white hover:bg-blue-52 focus:text-white`}
              />
            </div>
          </section>
        )}
      </Dialog>
    </>
  ) : null;
};
