import React from "react";
import { ButtonIcon } from "../../Widgets/Buttons";
import { AiOutlineClose } from "react-icons/ai";
import StripePaymentGateway from "../../Utility/Stripe/stripeGatewayProvider";
import { Dialog } from "@mui/material";

export const AddNewSubscriptionPayMethod = (props) => {
  return (
    props.trigger && (
      <Dialog
        open={true}
        aria-labelledby="Add New Payment Method"
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { height: "80%" } }}
        scroll="body"
        className="flex flex-col justify-center w-full rounded-lg"
      >
        <div className="flex mb-2 bg-blue-52 text-white font-semibold rounded-sm h-10">
          <div className="flex items-center justify-center flex-grow">
            Add New Payment Method
          </div>
          <div className="flex items-center justify-end pr-2 text-xl cursor-pointer">
            <ButtonIcon
              id="closeNewPayMethod"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineClose className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center m-6 gap-3">
          <p className="text-lg text-left">
            <StripePaymentGateway
              clientSecret={props.clientSecret}
              handleSuccess={(result) => {
                props.handleSuccess(result);
                props.setTrigger(false);
              }}
            />
          </p>
        </div>
      </Dialog>
    )
  );
};
