import React, { useState, useEffect } from "react";
import InputField, {
  InputObjectSelectField,
  InputZipCodeField,
} from "./InputFields";
import { StatesList } from "./FieldDependants";
import config from "../../config";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";

const AddressComponent = (props) => {
  const [compAddress1, setCompAddress1] = useState({
    label: props.address.address1 || "Search Address...",
    value: "",
  });

  useEffect(() => {
    if (props.address.address1) {
      setCompAddress1({
        label: props.address.address1 || "",
        value: props.address.address1 || "",
      });
    }
  }, [props.address.address1]);

  const handleAddress = (streetAddress, searchVal) => {
    const tempAddress = {
      address1: streetAddress,
      address2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    };
    tempAddress.address1 = streetAddress;
    geocodeByAddress(searchVal).then((place) => {
      for (var i = 0; i < place[0].address_components.length; i++) {
        if (place[0].address_components[i].types[0] === "neighborhood") {
          tempAddress.address2 = place[0].address_components[i].short_name;
        } else if (
          place[0].address_components[i].types[0] === "political" ||
          place[0].address_components[i].types[0] === "locality" ||
          place[0].address_components[i].types[0] === "sublocality_level_1"
        ) {
          tempAddress.city = place[0].address_components[i].long_name;
        } else if (
          place[0].address_components[i].types[0] ===
          "administrative_area_level_1"
        ) {
          tempAddress.state = place[0].address_components[i].short_name;
        } else if (place[0].address_components[i].types[0] === "country") {
          tempAddress.country = place[0].address_components[i].short_name;
        } else if (place[0].address_components[i].types[0] === "postal_code") {
          tempAddress.zipCode = place[0].address_components[i].long_name;
        }
      }

      props.setNewAddress(tempAddress);
      props.latLong && props.setLatitude(place[0].geometry.location.lat());
      props.latLong && props.setLongitude(place[0].geometry.location.lng());
    });
  };

  const handleOnChange = (event) => {
    props.setNewAddress({
      ...props.address,
      [event.target.name]: event.target.value,
    });
  };

  const handleZipOnChange = (value) => {
    props.setNewAddress({
      ...props.address,
      zipCode: value,
    });
  };

  return (
    <>
      {/* <InputField
        label="Address 1"
        name="address1"
        id="address1"
        type="text"
        innerRef={autocompleteRef}
        placeholder="Search Address"
        value={props.address.address1 || ""}
        onChange={handleOnChange}
        errorText={props.formErrors.address1}
        style={props.style}
      /> */}

      <label
        id="address1"
        htmlFor="Address1"
        className={`${props.style} text-sm w-80`}
      >
        Address 1
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <GooglePlacesAutocomplete
          apiKey={config.googleMap.API}
          autocompletionRequest={{
            componentRestrictions: { country: ["us"] },
            fields: ["address_component", "geometry"],
            types: ["address"],
          }}
          selectProps={{
            name: "address1",
            id: "address1",
            dataCy: "address1",
            value: compAddress1,
            onChange: (e) => {
              handleAddress(e.value.structured_formatting.main_text, e.label);
              const temp = {
                label: e.value.structured_formatting.main_text,
                value: e.value.structured_formatting.main_text,
              };
              setCompAddress1(temp);
            },
            placeholder: "Search Address",
            className: "mt-1",
          }}
        />
        {props.formErrors.address1 && (
          <span id="address1ErrorText" className="text-red-10">
            {props.formErrors.address1 || ""}
          </span>
        )}
      </label>

      <InputField
        label="Address 2"
        name="address2"
        id={`${props.for}Address2`}
        dataCy="address2"
        type="text"
        placeholder="Enter Address"
        value={props.address.address2 || ""}
        onChange={handleOnChange}
        errorText={props.formErrors.address2}
        style={props.style}
        notImp={true}
      />

      <InputField
        label="City"
        name="city"
        id={`${props.for}City`}
        dataCy="city"
        type="text"
        placeholder="Enter City"
        value={props.address.city || ""}
        onChange={handleOnChange}
        errorText={props.formErrors.city}
        style={props.style}
      />

      <InputObjectSelectField
        label="State"
        name="state"
        id={`${props.for}State`}
        dataCy="state"
        placeholder="Select a State"
        value={props.address.state || ""}
        onChange={handleOnChange}
        errorText={props.formErrors.state}
        options={StatesList}
        optionValue="value"
        optionName="name"
        style={props.style}
      />

      <InputObjectSelectField
        label="Country"
        name="country"
        id={`${props.for}Country`}
        dataCy="country"
        placeholder="Select a Country"
        value={props.address.country || ""}
        onChange={handleOnChange}
        errorText={props.formErrors.country}
        options={[{ name: "United States", value: "US" || "United States" }]}
        optionValue="value"
        optionName="name"
        style={props.style}
      />

      <InputZipCodeField
        label="Zipcode"
        name="zipCode"
        id={`${props.for}Zipcode`}
        data-cy="zipCode"
        type="text"
        placeholder="Enter Zipcode"
        value={props.address.zipCode || ""}
        onChange={(e) => {
          let value = "";
          const numericValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
          value = numericValue;

          handleZipOnChange(value);
        }}
        errorText={props.formErrors.zipCode}
        style={props.style}
      />

      {props.latLong && (
        <InputField
          label="Latitude"
          name="latitude"
          id="latitude"
          data-cy="latitude"
          type="number"
          notImp
          placeholder="Enter Latitude"
          value={props.latitude || ""}
          onChange={(e) => props.setLatitude(e.target.value)}
          errorText={props.formErrors.latitude}
          style={props.style}
        />
      )}

      {props.latLong && (
        <InputField
          label="Longitude"
          name="longitude"
          id="longitude"
          data-cy="longitude"
          type="number"
          notImp
          placeholder="Enter Longitude"
          value={props.longitude || ""}
          onChange={(e) => props.setLongitude(e.target.value)}
          errorText={props.formErrors.longitude}
          style={props.style}
        />
      )}
    </>
  );
};

export default AddressComponent;
