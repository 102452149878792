import React, { useState } from "react";
import { DownloadTenantDelinquencyPdfAPI } from "./reportsAPI";
import ExportCSV from "../../Widgets/ExportCSV";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import {
  GetDateInMMDDYYYY,
  GetDenominationWithDollar,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { ButtonIcon } from "../../Widgets/Buttons";
import { useNavigate } from "react-router-dom";
import { portfolioTreeStore } from "../../atoms";
import { useRecoilState } from "recoil";
import { BsDownload } from "react-icons/bs";

export const DelinquentReport = (props) => {
  let navigate = useNavigate();
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);
  const [tableData, setTableData] = useState(props.data || []);

  const downloadPDF = () => {
    let requestData = {
      portfolioId: props.portfolio.ID,
    };

    DownloadTenantDelinquencyPdfAPI(requestData).then((res) => {
      window.open(res.data.url, "_blank");
    });
  };

  return (
    <>
      <section className="flex justify-between items-center mb-2 mx-2">
        <div id="tableHeadlineText" className="font-semibold h-10 mx-4">
          Delinquent Tenants{" "}
          {props.portfolio.portfolioName
            ? `for ${props.portfolio.portfolioName}`
            : ``}{" "}
          As of {GetDateInMMDDYYYY(new Date())}
        </div>

        <div className="flex items-center gap-4">
          <ExportCSV
            data={tableData}
            keys={[
              {
                key: "address1",
                title: "Property Address/Unit No.",
                applyMethod: (row) => {
                  if (row.propertyType === "Single Family") {
                    return row.address1;
                  } else return row.unitNumber;
                },
              },
              {
                key: "portfolioName",
                title: "Portfolio Name",
              },
              {
                key: "tenantName",
                title: "Tenant Name",
              },
              {
                key: "phoneNumber",
                title: "Contact No.",
                applyMethod: (row) => PhoneNumFormat(row.phoneNumber),
              },
              {
                key: "email",
                title: "Email ID",
              },
              {
                key: "status",
                title: "Status",
                width: "100px",
              },
              {
                key: "currentBalance",
                title: "Current Balance",
              },
              {
                key: "rentPaid",
                title: "Rent Paid",
              },
              {
                key: "latePaymentCount",
                title: "Late Payment Count",
              },
              {
                key: "leaseStartDate",
                title: "Lease Start Date",
              },
              {
                key: "leaseEndDate",
                title: "Lease End Date",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
              },
              {
                key: "moveOutDate",
                title: "Move Out Date",
                applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
              },
              {
                title: "0-30 Days",
                key: "agedBalance.zeroToThirty",
                applyMethod: (row) => {
                  if (row.agedBalance.zeroToThirty) {
                    return "$" + row.agedBalance.zeroToThirty;
                  } else {
                    return "$0";
                  }
                },
              },
              {
                title: "31-60 Days",
                key: "agedBalance.thirtyToSixty",
                applyMethod: (row) => {
                  if (row.agedBalance.thirtyToSixty) {
                    return "$" + row.agedBalance.thirtyToSixty;
                  } else {
                    return "$0";
                  }
                },
              },
              {
                title: "61-90 Days",
                key: "agedBalance.sixtyToNinety",
                applyMethod: (row) => {
                  if (row.agedBalance.sixtyToNinety) {
                    return "$" + row.agedBalance.sixtyToNinety;
                  } else {
                    return "$0";
                  }
                },
              },
              {
                title: "90+ Days",
                key: "agedBalance.overNinety",
                applyMethod: (row) => {
                  if (row.agedBalance.overNinety) {
                    return "$" + row.agedBalance.overNinety;
                  } else {
                    return "$0";
                  }
                },
              },
            ]}
            fileName="Delinquency Report.csv"
            className={"bg-white"}
          />
          <ButtonIcon
            id="Download"
            btnName="download"
            dataCy="download"
            title="Download PDF"
            onClick={downloadPDF}
            icon={<BsDownload className="w-5 h-5" />}
            className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
          />
          <Searchbar
            //ref={searchBarRef}
            id="delinquencySearch"
            placeholder="Search..."
            data={props.data}
            matchProps={[
              { text: "address1" },
              { text: "unitNumber" },
              { text: "portfolioName" },
              { text: "tenantName" },
              { phoneNo: "phoneNumber" },
              { text: "email" },
              { text: "status" },
              { text: "currentBalance" },
              { text: "rentPaid" },
              { text: "latePaymentCount" },
              { text: "agedBalance.zeroToThirty" },
              { text: "agedBalance.thirtyToSixty" },
              { text: "agedBalance.sixtyToNinety" },
              { text: "agedBalance.overNinety" },
              { date: "leaseStartDate" },
              { date: "leaseEndDate" },
              { date: "moveOutDate" },
            ]}
            setTableData={setTableData}
          />
        </div>
      </section>

      <TableComponent
        tableId="delinquencyTable"
        className={"bg-white"}
        data={tableData}
        columns={[
          {
            key: "address1",
            title: "Property Address/Unit No.",
            applyMethod: (row) => {
              if (row.propertyType === "Single Family") {
                return row.address1;
              } else return row.unitNumber;
            },
          },
          {
            key: "portfolioName",
            title: "Portfolio Name",
            width: "100px",
          },
          {
            key: "tenantName",
            title: "Tenant Name",
            width: "120px",
            getCellStyles: (row) => {
              return { color: "blue", textDecoration: "underline" };
            },
            dataOnClick: (row) => {
              SetPortfolioTree({
                ...portfolioTree,
                tenant: {
                  unitID: row.unitId,
                  tenantCode: row.tenantCode,
                  tenantID: row.tenantId,
                  activeMenuItem: "rentalLedger",
                },
              });
              navigate("/tenants/tenantDetails");
            },
          },
          {
            key: "phoneNumber",
            title: "Contact No.",
            applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
          },
          {
            key: "email",
            title: "Email ID",
          },
          {
            key: "status",
            title: "Status",
            width: "100px",
          },
          {
            key: "currentBalance",
            title: "Current Balance",
            width: "100px",
            applyMethod: (row) => GetDenominationWithDollar(row.currentBalance),
          },
          {
            key: "rentPaid",
            title: "Rent Paid",
            width: "100px",
            applyMethod: (row) => GetDenominationWithDollar(row.rentPaid),
          },
          {
            key: "latePaymentCount",
            title: "Late Payment Count",
            width: "120px",
          },
          {
            key: "leaseStartDate",
            title: "Lease Start Date",
            width: "100px",
            applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
          },
          {
            key: "leaseEndDate",
            title: "Lease End Date",
            width: "100px",
            applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
          },
          {
            key: "moveOutDate",
            title: "Move Out Date",
            width: "100px",
            applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
          },
          {
            title: "Aged Balance",
            key: "agedBalance",
            colSpan: 4,
            getColumnStyles: (row) => {
              return {
                border: "1px solid #FFFFFF",
                backgroundColor: "#3876A1",
                color: "white",
              };
            },
            subColumns: [
              {
                title: "0-30 Days",
                key: "agedBalance.days0to30",
                applyMethod: (row) =>
                  GetDenominationWithDollar(row.agedBalance.zeroToThirty),
                getCellStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
                getSubcolumnStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
              },
              {
                title: "31-60 Days",
                key: "agedBalance.days31to60",
                applyMethod: (row) =>
                  GetDenominationWithDollar(row.agedBalance.thirtyToSixty),
                getCellStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
                getSubcolumnStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
              },
              {
                title: "61-90 Days",
                key: "agedBalance.days61to90",
                applyMethod: (row) =>
                  GetDenominationWithDollar(row.agedBalance.sixtyToNinety),
                getCellStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
                getSubcolumnStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
              },
              {
                title: "90+ Days",
                key: "agedBalance.days90plus",
                applyMethod: (row) =>
                  GetDenominationWithDollar(row.agedBalance.overNinety),
                getCellStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
                getSubcolumnStyles: (row) => {
                  return {
                    border: "1px solid #FFFFFF",
                    backgroundColor: "#3876A1",
                    color: "white",
                  };
                },
              },
            ],
          },
        ]}
        rowOnClick={(row) => {}}
      />
    </>
  );
};
