import React, { useEffect, useState } from "react";
import { userData } from "../../atoms";
import { Link } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { clientId } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { Loader } from "../../Widgets/notificationFeedbacks";
import Footer from "../../Widgets/Footer";
import { clickEffect } from "../../Widgets/CommonWidgets";

const Survey = () => {
  const [userInfo, setUserInfo] = useRecoilState(userData);
  const [surveySuccess, setsurveySuccess] = useState(false);
  const [noofprops, setNoofprops] = useState("");
  const [noofunits, setNoofunits] = useState("");
  // const [personName, setNoofStates] = useState("");
  const clientIdVal = useRecoilValue(clientId);
  //console.log("userid from recoil sur", clientIdVal);
  const [personName, setPersonName] = React.useState([]); //using this value as no.of states due to urgency.
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  let webApi = new WebApimanager();
  let navigate = useNavigate();

  const names = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {}, []);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value ", value);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = [];
    if (!noofprops) {
      errors.push("No of Properties are required!");
    }
    if (!noofunits) {
      errors.push("No of Units/Rentals are required!");
    }
    if (!personName.length) {
      errors.push("Active States are required!");
    }
    if (errors.length) {
      swal({
        title: "Error",
        text: errors.join("\n"),
        icon: "error",
        button: "ok",
      });
    } else {
      handlingSubmitSurvey(event);
    }
  };

  const handlingSubmitSurvey = (e) => {
    e.preventDefault();

    let data = {
      noOfProperties: noofprops || 0,
      noOfUnits: noofunits || 0,
      states: personName.join(", ") || [],
      clientId: clientIdVal,
    };
    webApi
      .post("/survey", data)
      .then((res) => {
        if (res.data.errorCode && res.data.errorCode !== 403) {
          swal("", res.data.errorMessage, "error");
        } else {
          setsurveySuccess(true);
          setUserInfo({ ...userInfo, surveyStatus: true });
          if (
            userInfo.isSubscribed === false &&
            userInfo.userRole === "Client Admin"
          ) {
            navigate("/subscription");
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Submit Survey , Please try again later.`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <section className="flex flex-col justify-center items-center w-1/4 fixed bg-blue-52 h-full">
        <img
          id="surveyPageLogo"
          className="w-64 h-20 md:h-14 md:w-40"
          src="logo/NEWLOGO.png"
          alt="Yvooa logo"
        />
        <span className="text-white text-xl text-center pl-5">
          <p>Your Residential Solutions;</p>
          <p>under one roof.</p>
        </span>
      </section>

      <section className="flex flex-col items-center justify-center h-full ml-[25%]">
        {!surveySuccess ? (
          <div className="text-xl mt-16 sm:text-base sm:mt-8">
            <div className="text-center flex place-content-center">
              <img
                id="noteIcon"
                className="h-28 mb-4"
                src="logo/NOTEICON.png"
                alt=""
              />
            </div>
            <p className="text-center">
              {" "}
              We read our customer's responses to ensure you have left with a
              positive experience.
              <br />
              Please take 2 minutes to fill this as your input's matter to us.
            </p>
            <form
              // onSubmit={handleSubmit}
              className="bg-white pt-6 pb-8 mb-4 text-center"
            >
              <div className="mt-10">
                <h3>How many properties do you own?</h3>
                <div className="flex place-content-center mt-4 pl-6 sm:flex-col">
                  <div className="flex items-center mr-10">
                    <input
                      id="inline-radio1"
                      type="radio"
                      value="1-5"
                      data-cy="oneToFiveProps"
                      name="inline-radio-group1"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofprops("1-5")}
                    />
                    <label
                      htmlFor="1-5"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      1-5
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio1"
                      type="radio"
                      value="5-10"
                      data-cy="fiveToTenProps"
                      name="inline-radio-group1"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofprops("5-10")}
                    />
                    <label
                      htmlFor="5-10"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      5-10
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio1"
                      type="radio"
                      data-cy="tenToTwentyProps"
                      value="10-20"
                      name="inline-radio-group1"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofprops("10-20")}
                    />
                    <label
                      htmlFor="10-20"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      10-20
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio1"
                      type="radio"
                      data-cy="twentyToThirtyProps"
                      value="20-30"
                      name="inline-radio-group1"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofprops("20-30")}
                    />
                    <label
                      htmlFor="20-30"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      20-30
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio1"
                      type="radio"
                      value="30+"
                      data-cy="thirtyPlus"
                      name="inline-radio-group1"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofprops("30+")}
                    />
                    <label
                      htmlFor="30+"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      30+
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <h3>How many total Units/Rentals do you want to manage?</h3>
                <div className="flex place-content-center mt-4 pl-6 sm:flex-col">
                  <div className="flex items-center mr-10">
                    <input
                      id="inline-radio"
                      type="radio"
                      value="1-5"
                      data-cy="oneToFiveUnits"
                      name="inline-radio-group"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofunits("1-5")}
                    />
                    <label
                      htmlFor="1-5"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      1-5
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio"
                      type="radio"
                      value="5-10"
                      data-cy="fiveToTenUnits"
                      name="inline-radio-group"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofunits("5-10")}
                    />
                    <label
                      htmlFor="5-10"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      5-10
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio"
                      type="radio"
                      value="10-20"
                      data-cy="tenToTwentyUnits"
                      name="inline-radio-group"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofunits("10-20")}
                    />
                    <label
                      htmlFor="10-20"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      10-20
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio"
                      type="radio"
                      value="20-30"
                      data-cy="twentyToThirtyUnits"
                      name="inline-radio-group"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofunits("20-30")}
                    />
                    <label
                      htmlFor="20-30"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      20-30
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="inline-radio"
                      type="radio"
                      value="30+"
                      data-cy="thirtyPlusUnits"
                      name="inline-radio-group"
                      className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={(e) => setNoofunits("30+")}
                    />
                    <label
                      htmlFor="30+"
                      className="ml-2 text-sm font-medium text-gray-900"
                    >
                      30+
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-10 text-center flex flex-col justify-center items-center">
                  <h3>What states are you active in?</h3>
                  <Select
                    id="states"
                    name="states"
                    data-cy="states"
                    className="mt-4 w-96"
                    multiple
                    displayEmpty
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select states</em>;
                      }

                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em>Placeholder</em>
                    </MenuItem>
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex items-center justify-center pt-12 bg-dark">
                <button
                  id="submitSurvey"
                  name="submitSurvey"
                  data-cy="submitSurvey"
                  className={
                    "bg-blue-52 w-96 sm:w-3/4 h-10 text-white flex justify-center items-center font-semibold py-2 px-4 border border-gray-400 rounded shadow " +
                    clickEffect
                  }
                  onClick={(e) => handleSubmit(e)}
                >
                  SUBMIT
                </button>
              </div>
              <div className="text-sm pb-2 mt-4 text-center">
                <button
                  id="skip"
                  name="skip"
                  data-cy="skip"
                  onClick={(e) => handlingSubmitSurvey(e)}
                  className={
                    "text-blue-500 focus:outline-none focus:underline hover:underline underline " +
                    clickEffect
                  }
                >
                  SKIP
                </button>
              </div>
            </form>
          </div>
        ) : (
          <></>
        )}
        {surveySuccess ? (
          <div className="grid place-content-center text-xl">
            <div className="text-center">
              <img
                id="successIcon"
                className="h-28 pl-40 mb-4"
                src="logo/SUCCESSICON.png"
                alt=""
              />
            </div>
            <p className="text-center">Thank you for Signing Up with YVOOA!</p>
            <p>Please click below to begin your journey with us.</p>
            <div className="text-md pb-2 mt-4 text-center">
              <a
                id="continue"
                name="continue"
                data-cy="continue"
                href="/dashboard"
                className="text-blue-500 focus:outline-none focus:underline hover:underline underline"
              >
                CONTINUE
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}

        <Footer />
      </section>
    </>
  );
};

export default Survey;
