import { WebApimanager } from "../WebApiManager";
import swal from "sweetalert";
let webApi = new WebApimanager();

// API to Get Profile Info
export const GetProfileInfoAPI = (email) => {
  return webApi
    .get(`/clients/${email}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage); // Optional: You can throw an error to be caught later
      }
      return res; // Return the response on success
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Profile Details couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error; // Throw error so it can be handled in the calling function
    });
};

// Get Portfolio By User ID
export const GetPortfoliosAPI = (userId) => {
  return webApi
    .get(`/portfolios/client/${userId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Portfolios couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// Get Properties by PortfolioId
export const GetPropertiesAPI = (portfolioId) => {
  return webApi
    .get(`/properties/portfolio/${portfolioId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Properties couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// Get Units by Property ID
export const GetUnitsAPI = (propertyId) => {
  return webApi
    .get(`/units/property/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Units couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// Get Tenants by Unit ID
export const GetTenantsAPI = (unitID) => {
  return webApi
    .get(`/tenant/tenantCode/unit/${unitID}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Tenants couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// Get Vendors by Company ID
export const GetVendorsAPI = (companyId) => {
  return webApi
    .get(`/maintenance/tickets/task/assignee/${companyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Vendors couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};
