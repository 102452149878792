import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentGatewayForm from "./stripePaymentGatewayForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripePaymentGateway(props) {
  const clientSecret = props.clientSecret;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <section>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <StripePaymentGatewayForm
            clientSecret={clientSecret}
            handleConfirm={(result) => props.handleSuccess(result)}
          />
        </Elements>
      )}
    </section>
  );
}
