import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreatePortfolio from "../CreatePortfolio";
import { Snackbar, Alert } from "@mui/material";
import { WebApimanager } from "../../WebApiManager";
import { BsThreeDotsVertical, BsPlusCircle } from "react-icons/bs";
import { clientId, portfolioTreeStore } from "../../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  TableComponent,
  Searchbar,
  GetDateInMMDDYYYY,
  clickEffect,
} from "../../Widgets/CommonWidgets";
import { SnackAlert } from "../../Widgets/notificationFeedbacks";
import swal from "sweetalert";
import { ButtonIcon, ButtonBlueOutline } from "../../Widgets/Buttons";
import { Loader } from "../../Widgets/notificationFeedbacks";

const PortfolioTable = (props) => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const clientIdVal = useRecoilValue(clientId);
  const [portfolioTree, setPortfolioTree] = useRecoilState(portfolioTreeStore);
  const [openPortfolioForm, setOpenPortfolioForm] = useState(false);
  const [editFolioData, setEditFolioData] = useState("");
  const [portfolios, setPortfolios] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showTableOptions, setShowTableOptions] = useState(false);
  const [formOperation, setFormOperation] = useState("");

  const [snackAlert, setSnackAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });

  useEffect(() => {
    getPortfolios();
  }, []);

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setPortfolios(res.data);
          setTableData(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Unable to get Portfolios, Please try again later.`,
          "error"
        );
      });
  };

  const deletePortfolio = (portfolio) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete the portfolio ${portfolio.portfolioName}!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/portfolios/" + portfolio.ID, { loader: true })
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Error", res.data.errorMessage, "error");
            } else {
              getPortfolios();
              swal(
                "Success",
                portfolio.portfolioName + " " + res.data.errorMessage,
                "success"
              );
            }
          })
          .catch((error) => {
            swal(
              "Error",
              `Oops! Unable to delete Portfolio ${portfolio.portfolioName}, Please try again later.`,
              "error"
            );
          });
      } else {
        return;
      }
    });
  };

  const GotoPropertyTable = (portfolio) => {
    setPortfolioTree({
      ...portfolioTree,
      portfolio: portfolio,
    });
    navigate("/portfolio/properties", { state: portfolio });
  };

  const portfolioTableActions = {
    editTitle: "Edit Portfolio",
    editOnClick: (row) => {
      setEditFolioData(row);
      setFormOperation("Edit");
      setOpenPortfolioForm(true);
    },
    deleteTitle: "Delete Portfolio",
    deleteOnClick: (row) => deletePortfolio(row),
  };

  return (
    <>
      {openPortfolioForm && (
        <CreatePortfolio
          trigger={openPortfolioForm}
          setTrigger={setOpenPortfolioForm}
          operation={formOperation}
          portfolio={editFolioData}
          getAllPortfolios={(portfolio) => getPortfolios()}
        />
      )}

      <SnackAlert
        open={snackAlert.open}
        type={snackAlert.type}
        message={snackAlert.message}
        handleSnackbar={snackAlert}
        handleClose={() => setSnackAlert({ ...snackAlert, open: false })}
      />

      <div className="w-5/6 m-auto">
        <span
          id="portfolioPageTitle"
          className={`flex justify-center items-center px-6 bg-blue-52 text-white font-semibold text-center rounded h-10`}
        >
          Portfolios
        </span>
        <section
          name="Searchbar and Options"
          className={"flex justify-between items-center my-4 mx-4"}
        >
          <Searchbar
            id="portfolioSearchBar"
            style="ml-2"
            placeholder="Search Portfolios"
            data={portfolios}
            matchProps={[
              { text: "portfolioName" },
              { text: "portfolioType" },
              { text: "ownership" },
              { date: "CreatedAt" },
              { text: "numberOfProperties" },
            ]}
            setTableData={setTableData}
          />

          <ButtonBlueOutline
            id="addNewPortfolio"
            btnName="addNewPortfolio"
            dataCy="addNewPortfolio"
            name={
              <>
                <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
              </>
            }
            onClick={(e) => {
              setOpenPortfolioForm(true);
              setFormOperation("New");
            }}
            className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
          />
        </section>

        <TableComponent
          tableId="portfolioTable"
          data={tableData}
          columns={[
            { key: "portfolioName", title: "Portfolio Name" },
            { key: "portfolioType", title: "Portfolio Type" },
            { key: "ownership", title: "Ownership" },
            {
              key: "CreatedAt",
              title: "Created On",
              applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            },
            { key: "numberOfProperties", title: "No. of Properties" },
            // { key: "-", title: "No. of Users" },
          ]}
          actionHeader="Actions"
          actions={portfolioTableActions}
          rowOnClick={GotoPropertyTable}
          //initialSort={{ key: "CreatedAt", direction: "descending" }}
        />
      </div>
    </>
  );
};

export default PortfolioTable;
