import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";

let webApi = new WebApimanager();

// API to Get All Subscription Plans
export const GetSubscriptionTypesAPI = () => {
  return webApi
    .get(`/client/subscriptionTypes`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage); // Optional: You can throw an error to be caught later
      }
      return res; // Return the response on success
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Subscriptions couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error; // Throw error so it can be handled in the calling function
    });
};

// API to Buy a Subscription Plan
export const BuySubscriptionAPI = (requestData) => {
  return webApi
    .post(`/client/subscription`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Failed to initiate the subscription purchase. Please try again. If the issue persists, contact support.",
        "error"
      );
      throw error;
    });
};

// API to Get All Subscription Details
export const GetSubscriptionDetailsAPI = (clientID) => {
  return webApi
    .get(`/client/${clientID}/subscription`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Subscription details couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Get All Subscription Details
export const GetSubscriptionListAPI = (clientID) => {
  return webApi
    .get(`/subscription/client/${clientID}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

// API to Change Subscription Plan
export const ChangeSubscriptionPlanAPI = (requestData) => {
  return webApi
    .put(`/client/subscription/plans`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Failed to change the subscription plan. Please try again. If the issue persists, contact support.",
        "error"
      );
      throw error;
    });
};

//API to get all subscription payment Invoices
export const DownloadSubscriptionInvoicesAPI = (requestData) => {
  return webApi
    .post(`/client/subscription/invoice`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Failed to load the subscription invoices. Please try again. If the issue persists, contact support.",
        "error"
      );
      throw error;
    });
};

//API to Create payment Method
export const CreatePaymentMethodAPI = (requestData) => {
  return webApi
    .post(`/payment/stripe/paymentMethods/subscription`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Something went wrong. Please try again. If the issue persists, contact support.",
        "error"
      );
      throw error;
    });
};

// API to Change Subscription Plan
export const ChangePaymentMethodAPI = (requestData) => {
  return webApi
    .put(`/client/subscription/paymentMethod`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Something went wrong. Please try again. If the issue persists, contact support.",
        "error"
      );
      throw error;
    });
};

//API to Create payment Method Options
export const GetPaymentMethodOptionsAPI = (requestData) => {
  return webApi
    .post(`/payment/stripe/paymentMethods`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Payment Methods couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//API to Create payment Method
export const GetPaymentMethodDetailsAPI = (clientId) => {
  return webApi
    .get(`/client/subscription/paymentDetails/${clientId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Payment Method details couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};
