import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { clickEffect } from "./CommonWidgets";

export const IconInCircle = (props) => {
  return (
    <>
      <div
        id={props.id}
        className={props.className + ` flex justify-center items-center`}
        title={props.title}
        style={{
          backgroundColor: props.color ?? "white",
          height: props.radius,
          width: props.radius,
          borderRadius: "50%",
        }}
      >
        {props.icon}
      </div>
    </>
  );
};

// Added InfoButton [XT-2936] 16th sep 2024 - @varunr@rapidviewtech
export const InfoIcon = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleClick = () => setIsActive(!isActive);

  const buttonClasses = isActive
    ? "bg-white text-blue-900"
    : isHovered
    ? "bg-blue-67 text-blue-52"
    : "bg-blue-52 text-white";
  return (
    <>
      <button
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`flex items-center rounded-full ${buttonClasses} hover:bg-blue-67 hover:text-blue-52 ml-2 ${clickEffect}`}
      >
        <BsInfoCircle className="w-5 h-5" />
      </button>
      {isActive && (
        <div
          className="inline-block bg-white text-black rounded shadow-lg p-1 absolute mt-20 font-medium  "
          style={{ left: props.left, zIndex: props.zIndex }}
        >
          {props.message}
        </div>
      )}
    </>
  );
};
