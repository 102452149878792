import React, { useState } from "react";
import { Auth } from "aws-amplify";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const ResetPass = () => {
  const [newPass, setNewpass] = useState("");
  const [conPass, setConPass] = useState("");
  let navigate = useNavigate();

  const handlesubmit = async (event) => {
    event.preventDefault();
    // await Auth.signIn(email, pass)
    //   .then((user) => {
    //     console.log("user", user);
    //     swal("Success", "Password Changed Successful", "success");
    //     navigate("/dashboard");
    //   })
    //   .catch((e) => {
    //     console.log("e", e);
    //     swal("Failure", "error", "error");
    //   });
  };

  return (
    <>
      <div className="flex min-h-screen w-full">
        <section className="flex flex-col w-1/4  bg-blue-52 justify-center items-center">
          <img
            id="loginPageLogo"
            className="w-64 h-20 md:h-14 md:w-40"
            src="logo/NEWLOGO.png"
            alt="Yvooa logo"
          />
          <span className="text-white text-xl text-center">
            <p>Your Residential Solutions;</p>
            <p>under one roof.</p>
          </span>
        </section>

        <div className="flex items-center justify-center max-w-md px-6 mx-auto w-3/4 sm:w-full">
          <div className="">
            <div className="text-center">
              <h2
                id="resetPasswordPageTitle"
                className="text-4xl font-bold text-center text-gray-700 underline sm:text-2xl sm:font-semibold"
              >
                Reset Password
              </h2>
            </div>

            <div className="mt-6">
              <form
                onSubmit={handlesubmit}
                className="bg-white  px-8 pt-6 pb-8 mb-4"
              >
                <div className="mb-4">
                  <label
                    id="newPasswordLabel"
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="newPass"
                  >
                    New Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="newPass"
                    id="newPasswordInput"
                    data-cy="newPass"
                    type="text"
                    placeholder="New Password"
                    defaultValue={newPass}
                    onChange={(e) => setNewpass(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label
                    id="confirmPasswordLabel"
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="conPass"
                  >
                    Confirm Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="conPass"
                    id="confirmPasswordInput"
                    data-cy="conPass"
                    type="text"
                    placeholder="Confirm Password"
                    defaultValue={conPass}
                    onChange={(e) => setConPass(e.target.value)}
                  />
                </div>

                <div className="flex flex-col">
                  <div className="flex items-center justify-center pt-2">
                    <button
                      className="bg-blue-52 w-96 h-10 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow sm:w-72"
                      id="resetPasswordButton"
                      name="resetPasswordBtn"
                      data-cy="resetPasswordBtn"
                    >
                      RESET
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-center text-black text-sm pt-4 text-center">
                  <p id="customerSupportText">
                    Questions? Contact Customer Support at{" "}
                    <p
                      id="customerSupportEmail"
                      className="focus:underline hover:underline text-blue-500"
                    >
                      support@rapidviewtech.com
                    </p>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPass;
