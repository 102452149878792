import React, { useState } from "react";
import { ButtonIcon } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import { DownloadWorkOrderReportPdfAPI } from "./reportsAPI";
import ExportCSV from "../../Widgets/ExportCSV";
import {
  GetDateInMMDDYYYY,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";

export const WorkOrdersReport = (props) => {
  const [tableData, setTableData] = useState(props.data || []);
  const downloadPDF = () => {
    let requestData = {
      vendorId: props.vendor.roleId,
    };

    DownloadWorkOrderReportPdfAPI(requestData).then((res) => {
      window.open(res.data.url, "_blank");
    });
  };

  return (
    <>
      <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
        <ExportCSV
          data={tableData}
          keys={[
            { key: "woid", title: "Work Order Id" },
            {
              key: "unitDetails.unitNumber",
              title: "Unit Number",
            },
            {
              key: "status",
              title: "Status",
            },
            {
              key: "CreatedAt",
              title: "Call Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            },
            {
              key: "vendor",
              title: "Vendor/Employee",
            },
            {
              key: "description",
              title: "Brief Description",
            },
          ]}
          fileName="Work Order Report.csv"
          className={"bg-white"}
        />

        <ButtonIcon
          id="Download"
          btnName="download"
          dataCy="download"
          title="Download PDF"
          onClick={downloadPDF}
          icon={<BsDownload className="w-5 h-5" />}
          className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
        />

        <Searchbar
          placeholder="Search..."
          data={props.data}
          matchProps={[
            { text: "woid" },
            { text: "unitDetails.unitNumber" },
            { text: "status" },
            { date: "CreatedAt" },
            { text: "vendor" },
            { text: "description" },
          ]}
          setTableData={setTableData}
        />
      </div>

      <TableComponent
        tableId="workOrderTable"
        className="bg-white"
        data={tableData}
        columns={[
          { key: "woid", title: "Work Order Id", width: "150px" },
          {
            key: "unitDetails.unitNumber",
            title: "Unit Number",
            width: "150px",
          },
          {
            key: "status",
            title: "Status",
            dataClassName: "font-medium",
            getCellStyles: (row) => {
              return { color: row.statusColor };
            },
            width: "100px",
          },
          {
            key: "CreatedAt",
            title: "Call Date",
            applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            width: "100px",
          },
          {
            key: "vendor",
            title: "Vendor/Employee",
            width: "200px",
          },
          {
            key: "description",
            title: "Brief Description",
            width: "260px",
          },
        ]}
        rowOnClick={() => {}}
        //initialSort={{ key: "ID", direction: "descending" }}
      />
    </>
  );
};
