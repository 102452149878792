// SubscriptionPage.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userData } from "../../atoms";
import {
  CreatePaymentMethodAPI,
  DownloadSubscriptionInvoicesAPI,
  GetPaymentMethodDetailsAPI,
  GetPaymentMethodOptionsAPI,
  GetSubscriptionDetailsAPI,
  GetSubscriptionListAPI,
} from "./subscriptionAPI";
import {
  clickEffect,
  GetDateInMMDDYYYY,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { MultiDetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { AddNewSubscriptionPayMethod } from "./addNewSubscriptionPayMethod";
import swal from "sweetalert";

const SubscriptionDetails = () => {
  let navigate = useNavigate();
  const userInfo = useRecoilValue(userData);
  const [activeMenu, setActiveMenu] = useState("subInfo");
  const [subDetails, setSubDetails] = useState({});
  const [subList, setSubList] = useState([]);
  const [payMethodOptions, setpayMethodOptions] = useState([]);
  const [payMethodDetails, setPayMethodDetails] = useState({});
  const [selectedPayMethod, setSelectedPayMethod] = useState({});
  const [openNPMform, setOpenNPMform] = useState(false);
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (
      userInfo.isSubscribed === false &&
      userInfo.userRole === "Client Admin"
    ) {
      navigate("/subscription/retry");
    } else {
      getSubscriptionDetails();
      getSubscriptionList();
    }
  }, []);

  const getSubscriptionDetails = () => {
    GetSubscriptionDetailsAPI(userInfo.ID).then((res) => {
      setSubDetails(res.data);
    });
  };

  const getSubscriptionList = () => {
    GetSubscriptionListAPI(userInfo.ID).then((res) => {
      setSubList(res.data.reverse());
    });
  };

  const getPaymentInvoices = () => {
    DownloadSubscriptionInvoicesAPI({ clientId: userInfo.ID }).then((res) => {
      setInvoices(res.data);
    });
  };

  const getPaymentMethods = () => {
    setSelectedPayMethod({});
    GetPaymentMethodOptionsAPI({ clientId: userInfo.ID }).then((res) => {
      setpayMethodOptions([
        ...res.data.cards,
        ...res.data.usBankAccounts,
        { ID: "0", paymentMethodDetails: "Add New" },
      ]);
      getPaymentMethodDetails();
    });
  };

  const getPaymentMethodDetails = () => {
    GetPaymentMethodDetailsAPI(userInfo.ID).then((res) => {
      setPayMethodDetails(res.data);
      const currentPayMethod =
        payMethodOptions.find(
          (item) => item.paymentMethodDetails === res.data.paymentFrom
        ) || {};
      console.log(currentPayMethod);
      setSelectedPayMethod(currentPayMethod);
    });
  };

  const createPaymentMethod = () => {
    CreatePaymentMethodAPI({ clientId: userInfo.ID }).then((res) => {
      setClientSecretKey(res.data.clientSecret);
      setOpenNPMform(true);
    });
  };

  const handleAddNewPaymentMethod = () => {
    swal({
      title: "",
      text: `Please make your first transaction of $1 to turn on 
    Auto-Payment for new payment method, the deducted amount will be refunded in 2-3 working days.`,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      // dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        createPaymentMethod();
      } else {
        return;
      }
    });
  };

  const subTypeColorsCodes = {
    FREE: "#38B37E",
    BASIC: "#FFAF09",
    PREMIUM: "#FF6522",
    ADVANCED: "#3661A9",
  };

  return (
    <>
      {openNPMform && (
        <AddNewSubscriptionPayMethod
          trigger={openNPMform}
          setTrigger={setOpenNPMform}
          clientSecret={clientSecretKey}
          handleSuccess={(result) => {
            getPaymentMethods();
          }}
        />
      )}

      <div className="w-5/6 m-auto">
        <span
          id="subScriptionPageTitle"
          className={`flex justify-center items-center px-6 bg-blue-52 text-white font-semibold text-center rounded h-10`}
        >
          Subscription
        </span>

        <section className="flex">
          {/* Sidebar */}
          <div className="w-1/4 bg-white p-6 gap-y-4">
            <div className="bg-[#F5F9FF] border rounded-md mb-4 py-2 px-1">
              <h2 id="companyNameTitle">Company Name:</h2>
              <p id="companyNameData" className="text-xl font-semibold">
                {subDetails.companyName}
              </p>
            </div>

            <button
              id="subInfo"
              onClick={(e) => setActiveMenu("subInfo")}
              className={`w-full text-left py-2 px-4 rounded-md ${clickEffect} ${
                activeMenu === "subInfo"
                  ? "bg-gray-200 text-gray-800 font-semibold"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              Subscription Info
            </button>

            <button
              id="payMethod"
              onClick={(e) => {
                getPaymentMethods();
                setActiveMenu("payMethod");
              }}
              className={`w-full text-left py-2 px-4 rounded-md ${clickEffect} ${
                activeMenu === "payMethod"
                  ? "bg-gray-200 text-gray-800 font-semibold"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              Payment Method
            </button>

            <button
              id="payHistory"
              onClick={(e) => {
                getPaymentInvoices();
                setActiveMenu("payHistory");
              }}
              className={`w-full text-left py-2 px-4 rounded-md ${clickEffect} ${
                activeMenu === "payHistory"
                  ? "bg-gray-200 text-gray-800 font-semibold"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
            >
              Payment History
            </button>
          </div>

          {/* Main Content */}

          {/* Subscription Information */}
          {activeMenu === "subInfo" && (
            <section className="w-3/4 p-6">
              <h2 id="accountText" className="text-2xl font-bold">
                Account
              </h2>
              <p id="subID" className="text-gray-600">
                Subscription ID: {subDetails.subscriptionCode}
              </p>

              <div className="flex justify-between items-center mt-6 bg-white shadow rounded-lg p-6 mb-6">
                <div>
                  <div className="flex">
                    <span
                      id="subType"
                      className={`inline-block bg-[${
                        subTypeColorsCodes[subDetails.subscriptionType]
                      }] text-white p-3 pr-24`}
                    >
                      Subscription: {subDetails.subscriptionType}
                    </span>
                    <span
                      style={{
                        borderLeft: `35px solid ${
                          subTypeColorsCodes[subDetails.subscriptionType]
                        }`,
                      }}
                      className={`w-0 h-0 border-t-transparent border-t-[25px] border-b-[25px] border-b-transparent`}
                    ></span>
                  </div>

                  <p id="subAmount" className="text-4xl font-semibold my-2">
                    ${subDetails.amount}.00
                    <span className="text-lg font-normal">/mo</span>
                  </p>

                  <MultiDetailsTile
                    details={{
                      "Product Type:": subDetails.productType,
                      "Subscription Start:": GetDateInMMDDYYYY(
                        subDetails.subscriptionStart
                      ),
                      "Renewal Period:": subDetails.renewalPeriod,
                      "Payment Method:": subDetails.paymentMethod,
                      "Next Payment:": GetDateInMMDDYYYY(
                        subDetails.nextPaymentDate
                      ),
                    }}
                    labelClassName="text-gray-500 mb-2"
                  />
                </div>
                <div className="text-right space-y-2 flex flex-col items-start">
                  <p id="quickActions" className="font-semibold">
                    Quick Actions
                  </p>
                  <button
                    id="changePlan"
                    onClick={(e) => {
                      navigate("/subscription", {
                        state: {
                          currentSubscription:
                            subDetails.subscriptionType || "",
                        },
                      });
                    }}
                    className={`text-blue-500 hover:underline ${clickEffect}`}
                  >
                    Change Plan
                  </button>

                  <button
                    id="changePlan"
                    onClick={(e) =>
                      swal("", "Our Team is working on it!", "info")
                    }
                    className={`text-blue-500 hover:underline ${clickEffect}`}
                  >
                    Cancel Subscription
                  </button>

                  <button
                    id="changePlan"
                    onClick={(e) =>
                      swal("", "Our Team is working on it!", "info")
                    }
                    className={`text-blue-500 hover:underline ${clickEffect}`}
                  >
                    Switch to Annual Plan
                  </button>

                  <button
                    id="changePlan"
                    onClick={(e) => {
                      getPaymentMethods();
                      setActiveMenu("payMethod");
                    }}
                    className={`text-blue-500 hover:underline ${clickEffect}`}
                  >
                    Update payment method
                  </button>
                </div>
              </div>

              <Accordion>
                <AccordionSummary
                  expandIcon={<IoIosArrowDown />}
                  id={"subDetailsExpandBar"}
                >
                  <div
                    id="subDetailsTitleText"
                    className="font-semibold text-lg"
                  >
                    Subscription Details
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <TableComponent
                    tableId="subListTable"
                    data={subList}
                    columns={[
                      { key: "subscriptionCode", title: "Subscription ID" },
                      { key: "item", title: "Item" },
                      { key: "portfolios", title: "Portfolios" },
                      {
                        key: "units",
                        title: "No Of Units",
                        applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                      },
                      { key: "users", title: "No. of Users" },
                      { key: "amount", title: "Total/mo" },
                    ]}
                    //initialSort={{ key: "CreatedAt", direction: "descending" }}
                  />
                </AccordionDetails>
              </Accordion>
            </section>
          )}

          {/* Payment History */}
          {activeMenu === "payMethod" && (
            <section className="w-3/4 p-6">
              <h2 id="paymentDetailsTitle" className="text-2xl font-bold">
                Payment Details
              </h2>
              <p id="subID" className="text-gray-600 mb-6">
                Subscription ID: {subDetails.subscriptionCode}
              </p>

              <div className="w-full mx-auto p-6 bg-white rounded-md shadow-md border">
                <div className={`flex items-center`} id="">
                  <span className={`mr-10`}>Select Payment Method</span>
                  <InputObjectSelectField
                    //label="Select Payment Method"
                    name="paymentMethod"
                    id="paymentMethod"
                    notImp
                    dataCy="paymentMethod"
                    placeholder="Choose"
                    value={selectedPayMethod.ID}
                    onChange={(e) => {
                      let method = payMethodOptions.filter(
                        (item) => item.ID === e.target.value
                      );
                      if (e.target.value === "0") {
                        handleAddNewPaymentMethod();
                      } else {
                        setSelectedPayMethod(method[0]);
                      }
                    }}
                    options={payMethodOptions}
                    optionValue="ID"
                    optionName="paymentMethodDetails"
                    style="!w-96 flex justify-between items-center"
                  />
                </div>

                <h3 className="font-medium my-4">Payment Details</h3>

                <MultiDetailsTile
                  details={{
                    "Payment From:": payMethodDetails.paymentFrom,
                    "Payment Date:": payMethodDetails.paymentDate,
                    "Payment Type:": payMethodDetails.paymentType,
                    "Payment Amount:": `$${payMethodDetails.paymentAmount}`,
                  }}
                  labelClassName="text-gray-500 mb-2"
                />
              </div>
            </section>
          )}

          {/* Payment History */}
          {activeMenu === "payHistory" && (
            <section className="w-3/4 p-6">
              <h2 id="paymentHistoryTitle" className="text-2xl font-bold">
                Payment History
              </h2>
              <p id="subID" className="text-gray-600 mb-6">
                Subscription ID: {subDetails.subscriptionCode}
              </p>

              <p className="font-semibold p-2">Billing Details</p>
              <TableComponent
                tableId="subListTable"
                data={invoices}
                columns={[
                  { key: "invoiceId", title: "Invoice No" },
                  { key: "invoiceDate", title: "Date Issued" },
                ]}
                actions={{
                  downloadTitle: "Download",
                  downloadOnclick: (row) => {
                    window.open(row.invoiceURL, "_blank");
                  },
                }}
                //initialSort={{ key: "CreatedAt", direction: "descending" }}
              />
            </section>
          )}
        </section>
      </div>
    </>
  );
};

export default SubscriptionDetails;
