import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { motion } from "framer-motion"; // Framer for animations
import {
  InputObjectSelectField,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";
import { ButtonBlueOutline, ButtonCustom } from "../../Widgets/Buttons";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  GetPortfoliosAPI,
  GetPropertiesAPI,
  GetTenantsAPI,
  GetUnitsAPI,
  GetVendorsAPI,
} from "../../CentralizedAPI/commonAPI";
import { useRecoilValue } from "recoil";
import { userData } from "../../atoms";
import swal from "sweetalert";

//API
import {
  GetDelinquentReport,
  GetRentalLedgerByTenantCodeAPI,
  GetRentRollReport,
  GetTenantDirectoryReportAPI,
  GetUnitDirectoryReport,
  GetVacateNoticeReport,
  GetWorkOrderReportAPI,
} from "./reportsAPI";

//Components
import { RentalLedgerReport } from "./rentalLedgerReport";
import { TenantDirectoryReport } from "./tenantDirectoryReport";
import { DelinquentReport } from "./delinquentReport";
import { VacateNoticeReport } from "./vacateNoticeReport";
import { UnitsDirectoryReport } from "./unitDirectoryReport";
import { WorkOrdersReport } from "./workOrdersReport";
import { RentRollsReport } from "./rentRollsReport";

const ReportBuilder = () => {
  const userInfo = useRecoilValue(userData);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [reportTitle, setReportTitle] = useState("");

  //Report Category Variables
  const [selReportCategory, setSelReportCategory] = useState("");
  const reportCategoryOptions = [
    { name: "Property", accessor: "property" },
    { name: "Tenant", accessor: "tenant" },
    { name: "Unit", accessor: "unit" },
    { name: "Work Orders", accessor: "workOrders" },
    { name: "Audit History", accessor: "auditHistory" },
    { name: "Accounting", accessor: "accounting" },
  ];

  //Report Type Variables
  const [selectedReportName, setSelectedReportName] = useState("");
  const reportsOptions = {
    property: [
      {
        name: "Rental Ledger Report",
        accessor: "rentalLedgerReport",
      },
    ],

    tenant: [
      {
        name: "Tenant Directory Report",
        accessor: "tenantDirectoryReport",
      },
      {
        name: "Delinquency Report",
        accessor: "delinquencyReport",
      },
      {
        name: "Vacate Notices Report",
        accessor: "vacateNoticeReport",
      },
    ],

    unit: [
      {
        name: "Unit Directory Report",
        accessor: "unitDirectoryReport",
      },
    ],

    workOrders: [
      {
        name: "Work Order Report",
        accessor: "workOrderReport",
      },
    ],

    auditHistory: [
      {
        name: "Rent Roll Report",
        accessor: "rentRollReport",
      },
    ],

    accounting: [
      {
        name: "P&L Report",
        accessor: "pandlReport",
      },
    ],
  };

  //Report Builder Variables
  const [selPortfolio, setSelPortfolio] = useState({});
  const [selProperty, setSelProperty] = useState({});
  const [selUnit, setSelUnit] = useState({});
  const [selTenant, setSelTenant] = useState({});
  const [selVedor, setSelVendor] = useState({});
  const [selReportPeriod, setSelReportPeriod] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [propertyOptions, setPropertiesOptions] = useState([]);
  const [unitOptions, setUnitsOptions] = useState([]);
  const [tenantOptions, setTenantsOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const reportPeriodOptions = [
    "All",
    "Custom",
    "Last 3 Months",
    "Last 6 Months",
    "Last Year",
    "Year to Date",
  ];
  const [errors, setErrors] = useState({});

  //Data
  const [reportData, setReportData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    getPortfolios();
  }, []);

  const getPortfolios = () => {
    GetPortfoliosAPI(userInfo.ID).then((res) => {
      const sortedData =
        res.data.length > 1
          ? res.data.sort((a, b) =>
              a.portfolioName > b.portfolioName ? 1 : -1
            )
          : res.data;

      setPortfolioOptions(sortedData);
    });
  };

  const getVendorsList = () => {
    GetVendorsAPI(userInfo.companyID).then((res) => {
      setVendorOptions(res.data.vendors);
    });
  };

  // handling Selcted Report builder Fields Values
  const handlePortfolioSelect = (portfolioId) => {
    if (
      selectedReportName !== "delinquencyReport" &&
      selectedReportName !== "workOrderReport" &&
      selectedReportName !== "pandlReport" &&
      selectedReportName !== "vacateNoticeReport"
    ) {
      GetPropertiesAPI(portfolioId).then((res) => {
        setPropertiesOptions(
          res.data.length > 1
            ? res.data.sort((a, b) =>
                a.propertyName > b.propertyName ? 1 : -1
              )
            : res.data
        );
      });
    }
    const selcPort =
      portfolioOptions.find((item) => item.ID === parseInt(portfolioId)) || {};
    setSelPortfolio(selcPort);
    setSelProperty({});
    setPropertiesOptions([]);
    setSelUnit({});
    setUnitsOptions([]);
    setSelTenant({});
    setTenantsOptions([]);
  };

  const handlePropertySelect = (propertyId) => {
    if (selectedReportName === "rentalLedgerReport") {
      GetUnitsAPI(propertyId).then((res) => {
        setUnitsOptions(
          res.data.length > 1
            ? res.data.sort((a, b) => (a.unitNumber > b.unitNumber ? 1 : -1))
            : res.data
        );
      });
    }
    const selcProp =
      propertyOptions.find((item) => item.ID === parseInt(propertyId)) || {};
    setSelProperty(selcProp);
    setSelUnit({});
    setUnitsOptions([]);
    setSelTenant({});
    setTenantsOptions([]);
  };

  const handleUnitSelect = (unitId) => {
    if (selectedReportName === "rentalLedgerReport") {
      GetTenantsAPI(unitId).then((res) => {
        setTenantsOptions(res.data.sort());
      });
    }
    const selcUnit = unitOptions.find((item) => item.ID === unitId) || {};
    setSelUnit(selcUnit);
    setSelTenant({});
    setTenantsOptions([]);
  };

  const handleTenantSelect = (tenantCode) => {
    const selcTenant =
      tenantOptions.find((item) => item.tenantCode === tenantCode) || {};
    setSelTenant(selcTenant);
  };

  const handleVendorSelct = (vendorId) => {
    const selcVendor =
      vendorOptions.find((item) => item.roleId === vendorId) || {};
    setSelVendor(selcVendor);
  };

  //Report builder Fields
  const portfolioSelectField = () => (
    <InputObjectSelectField
      //label="Portfolio"
      name="portfolioName"
      id="selectPortfolio"
      notImp
      placeholder="Select Portfolio"
      value={selPortfolio.ID}
      onChange={(e) => handlePortfolioSelect(parseInt(e.target.value))}
      options={portfolioOptions}
      optionValue="ID"
      optionName="portfolioName"
      className="w-full bg-white"
      errorText={errors.selPortfolio}
    />
  );

  const propertySelectField = () => (
    <InputObjectSelectField
      name="property"
      id="propertySelect"
      notImp
      placeholder="Select Property"
      value={selProperty.ID}
      onChange={(e) => handlePropertySelect(parseInt(e.target.value))}
      options={propertyOptions}
      optionValue="ID"
      optionName="propertyName"
      style=""
      className="w-full bg-white"
      errorText={errors.selProperty}
    />
  );

  const unitSelectField = () => (
    <InputObjectSelectField
      name="unit"
      id="unitSelect"
      notImp
      placeholder="Select Unit"
      value={selUnit.ID}
      onChange={(e) => handleUnitSelect(parseInt(e.target.value))}
      options={unitOptions}
      optionValue="ID"
      optionName="unitNumber"
      style=""
      className="w-full bg-white"
      errorText={errors.selUnit}
    />
  );

  const tenantSelectField = () => (
    <InputObjectSelectField
      name="tenantCode"
      id="tenantSelect"
      notImp
      placeholder="Select Tenant"
      value={selTenant.tenantCode}
      onChange={(e) => handleTenantSelect(e.target.value)}
      options={tenantOptions}
      optionValue="tenantCode"
      optionName="tenantName"
      style=""
      className="w-full bg-white"
      errorText={errors.selTenant}
    />
  );

  const vendorSelectField = () => (
    <InputObjectSelectField
      //label="Vendor"
      name="vendor"
      id="vendor"
      placeholder="Select Vendor"
      notImp
      value={selVedor}
      onChange={(e) => handleVendorSelct(parseInt(e.target.value))}
      options={vendorOptions}
      optionValue="roleId"
      optionName="name"
      style=""
      className="w-full bg-white"
      errorText={errors.selVedor}
    />
  );

  const reportSelectField = () => (
    <InputSelectField
      name="reportPeriods"
      id="reportPeriods"
      notImp
      placeholder="Select Report Period"
      value={selReportPeriod}
      onChange={(e) => {
        handleReportPeriodChange(e.target.value);
      }}
      options={reportPeriodOptions}
      style=""
      className="w-full bg-white"
      errorText={errors.selReportPeriod}
    />
  );

  const fromDateField = () => (
    <>
      <DatePicker
        readOnly={selReportPeriod !== "Custom"}
        value={dayjs(fromDate)}
        onChange={(e) => setFromDate(dayjs(e.$d).format("MM/DD/YYYY"))}
        placeholder="MM/DD/YYYY"
        sx={styles.datePicker}
        className="w-full"
      />
      <span id={"fromErrorText"} className="text-red-10 text-sm">
        {errors.fromDate}
      </span>
    </>
  );

  const toDateField = () => (
    <>
      <DatePicker
        readOnly={selReportPeriod !== "Custom"}
        value={dayjs(toDate)}
        onChange={(e) => setToDate(dayjs(e.$d).format("MM/DD/YYYY"))}
        placeholder="MM/DD/YYYY"
        sx={styles.datePicker}
        className="w-full"
      />
      <span id={"toErrorText"} className="text-red-10 text-sm">
        {errors.toDate}
      </span>
    </>
  );

  const resetButton = () => (
    <ButtonCustom
      id="clearAll"
      name="Clear All"
      onClick={(e) => handleReportBuilderReset(e)}
      className="px-2 py-1 text-center w-24 bg-transparent rounded text-gray-600 underline hover:bg-gray-600 hover:text-white !shadow-none"
    />
  );

  const applyButton = (reportName) => (
    <ButtonBlueOutline
      id="apply"
      name="Apply"
      onClick={(e) => handleCreateReport(reportName)}
      className="!px-2 !py-1 text-center w-24 !h-fit"
    />
  );

  //Helper Functions
  const handleReportPeriodChange = (period) => {
    const currentDate = dayjs();
    let from = "";
    let to = "";
    if (period === "Custom" || period === "All") {
      setFromDate(fromDate);
      setToDate(toDate);
      return setSelReportPeriod(period);
    } else if (period === "Last 3 Months") {
      from = currentDate.subtract(3, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last 6 Months") {
      from = currentDate.subtract(6, "month").startOf("month");
      to = currentDate.endOf("day");
    } else if (period === "Last Year") {
      from = dayjs().subtract(1, "year").startOf("year");
      to = from.endOf("year");
    } else if (period === "Year to Date") {
      from = currentDate.startOf("year");
      to = currentDate.endOf("day");
    }
    if (from && to) {
      setFromDate(fromDate);
      setToDate(toDate);
      setSelReportPeriod(period);
    }
  };

  const handleReportBuilderReset = (e) => {
    e.preventDefault();
    setSelPortfolio({});
    setSelProperty({});
    setSelUnit({});
    setSelTenant({});
    setSelReportPeriod("");
    setFromDate("");
    setToDate("");
    setShowTable(false);
    setReportData([]);
  };

  const validateReportBuilder = () => {
    const errorObj = {};
    if (selectedReportName === "rentalLedgerReport") {
      if (!selPortfolio || !selPortfolio.ID) {
        errorObj.selPortfolio = "Portfolio is required!";
      }
      if (!selProperty || !selProperty.ID) {
        errorObj.selProperty = "Property is required!";
      }
      if (!selUnit || !selUnit.ID) {
        errorObj.selUnit = "Unit is required!";
      }
      if (!selTenant || !selTenant.tenantCode) {
        errorObj.selTenant = "Tenant is required!";
      }
      if (!selReportPeriod) {
        errorObj.selReportPeriod = "Report Period is required!";
      }
      if (selReportPeriod === "Custom" && !fromDate) {
        errorObj.fromDate = "From Date is required!";
      }
      if (selReportPeriod === "Custom" && !toDate) {
        errorObj.toDate = "To Date is required!";
      }
    } else if (
      selectedReportName === "tenantDirectoryReport" ||
      selectedReportName === "unitDirectoryReport" ||
      selectedReportName === "rentRollReport"
    ) {
      if (!selPortfolio || !selPortfolio.ID) {
        errorObj.selPortfolio = "Portfolio is required!";
      }
      if (!selProperty || !selProperty.ID) {
        errorObj.selProperty = "Property is required!";
      }
    } else if (
      selectedReportName === "delinquencyReport" ||
      selectedReportName === "vacateNoticeReport"
    ) {
      if (!selPortfolio || !selPortfolio.ID) {
        errorObj.selPortfolio = "Portfolio is required!";
      }
    } else if (selectedReportName === "workOrderReport") {
      if (!selVedor || !selVedor.roleId) {
        errorObj.selVedor = "Vendor is required!";
      }
    }

    setErrors(errorObj);
    console.log("errros", errorObj);

    if (Object.keys(errorObj).length > 0) return false;
    else return true;
  };

  const handleCreateReport = (reportName) => {
    if (!validateReportBuilder()) return;

    if (reportName === "rentalLedgerReport") {
      const requestData = {
        startDate: fromDate,
        endDate: toDate,
        leaseStatus: "Both",
      };
      GetRentalLedgerByTenantCodeAPI(selTenant.tenantCode, requestData).then(
        (res) => {
          setReportData(res.data);
          setReportTitle("Rental Ledger Report");
          setShowTable(true);
        }
      );
    } else if (reportName === "tenantDirectoryReport") {
      GetTenantDirectoryReportAPI(selProperty.ID).then((res) => {
        setReportData(res.data);
        setReportTitle("Tenant Directory Report");
        setShowTable(true);
      });
    } else if (reportName === "delinquencyReport") {
      GetDelinquentReport(0, selPortfolio.ID).then((res) => {
        setReportData(res.data);
        setReportTitle("Delinquency Report");
        setShowTable(true);
      });
    } else if (reportName === "vacateNoticeReport") {
      GetVacateNoticeReport(0, selPortfolio.ID).then((res) => {
        setReportData(res.data);
        setReportTitle("Vacate Notice Report");
        setShowTable(true);
      });
    } else if (reportName === "unitDirectoryReport") {
      GetUnitDirectoryReport(selProperty.ID).then((res) => {
        setReportData(res.data);
        setReportTitle("Unit Directory Report");
        setShowTable(true);
      });
    } else if (reportName === "workOrderReport") {
      GetWorkOrderReportAPI(selVedor.roleId).then((res) => {
        setReportData(res.data);
        setReportTitle("Work Order Report");
        setShowTable(true);
      });
    } else if (reportName === "rentRollReport") {
      GetRentRollReport(selProperty.ID).then((res) => {
        setReportData(res.data);
        setReportTitle("Rent Roll Report");
        setShowTable(true);
      });
    }
  };

  return (
    <div className="w-5/6 m-auto flex items-start">
      <motion.section
        animate={{ width: isCollapsed ? "80px" : "26%" }} // Collapse or expand
        transition={{ duration: 0.3 }}
        className="bg-gray-100 py-6 px-4 overflow-hidden h-full"
      >
        {/* Report Builder Header */}
        <div
          className="flex items-center mb-4 cursor-pointer"
          onClick={(e) => setIsCollapsed(!isCollapsed)}
        >
          <motion.div
            animate={{ rotate: isCollapsed ? 90 : 0 }} // Rotate icon when collapsed
            transition={{ duration: 0.3 }}
          >
            <AiOutlineMenu className="mr-2 text-blue-52 text-2xl" />
          </motion.div>
          {!isCollapsed && (
            <h2 className="text-blue-52 font-semibold">Report Builder</h2>
          )}
        </div>

        {!isCollapsed && (
          <>
            <label className="text-sm font-semibold mb-2">Select Report:</label>
            <div className="space-y-2">
              <InputObjectSelectField
                //label="Select Report"
                name="selectReportCategory"
                id="selectReportCategory"
                notImp
                placeholder="Select Report Category"
                value={selReportCategory}
                onChange={(e) => {
                  setSelReportCategory(e.target.value);
                  setSelectedReportName("");
                  handleReportBuilderReset(e);
                }}
                options={reportCategoryOptions}
                optionValue="accessor"
                optionName="name"
                style=""
                className="w-full bg-white"
              />

              <InputObjectSelectField
                //label="Select Report"
                name="reportName"
                id="reportName"
                notImp
                placeholder="Select Report"
                value={selectedReportName}
                onChange={(e) => {
                  if (e.target.value === "workOrderReport") {
                    getVendorsList();
                  } else if (e.target.value === "pandlReport") {
                    swal("", "Our Team is working on it!", "info");
                    return;
                  }
                  setSelectedReportName(e.target.value);
                  handleReportBuilderReset(e);
                }}
                options={reportsOptions[selReportCategory]}
                optionValue="accessor"
                optionName="name"
                style=""
                className="w-full bg-white"
              />
            </div>

            {selectedReportName && (
              <>
                <label className="block text-sm mb-2 text-gray-500 mt-10">
                  Report Builder
                </label>
                <section className="space-y-2 pb-2">
                  {selectedReportName === "rentalLedgerReport" && (
                    <>
                      {portfolioSelectField()}
                      {propertySelectField()}
                      {unitSelectField()}
                      {tenantSelectField()}
                      {reportSelectField()}
                      {selReportPeriod === "Custom" && fromDateField()}
                      {selReportPeriod === "Custom" && toDateField()}
                    </>
                  )}

                  {(selectedReportName === "tenantDirectoryReport" ||
                    selectedReportName === "unitDirectoryReport" ||
                    selectedReportName === "rentRollReport") && (
                    <>
                      {portfolioSelectField()}
                      {propertySelectField()}
                    </>
                  )}

                  {(selectedReportName === "delinquencyReport" ||
                    selectedReportName === "vacateNoticeReport") &&
                    portfolioSelectField()}

                  {selectedReportName === "workOrderReport" &&
                    vendorSelectField()}

                  <div className="flex justify-end items-center gap-4 !my-6">
                    {resetButton()}
                    {applyButton(selectedReportName)}
                  </div>
                </section>
              </>
            )}
          </>
        )}
      </motion.section>

      {/* Main Content Area */}
      <div
        className={`h-screen bg-gray-100 ml-6 ${
          isCollapsed ? "w-11/12" : "w-3/4"
        } `}
      >
        <span
          id="portfolioPageTitle"
          className={`flex justify-center items-center px-6 bg-blue-52 text-white font-semibold text-center rounded h-10`}
        >
          {reportTitle}
        </span>

        <section className="border py-8 h-full rounded overflow-x-auto">
          {!showTable && (
            <p className="text-gray-500 text-center py-auto">
              Please use the Report Builder to generate your Reports.
            </p>
          )}

          {showTable && (
            <>
              {selectedReportName === "rentalLedgerReport" && (
                <RentalLedgerReport
                  data={reportData}
                  tenant={selTenant}
                  startDate={fromDate}
                  endDate={toDate}
                />
              )}

              {selectedReportName === "tenantDirectoryReport" && (
                <TenantDirectoryReport
                  data={reportData}
                  property={selProperty}
                />
              )}

              {selectedReportName === "delinquencyReport" && (
                <DelinquentReport data={reportData} portfolio={selPortfolio} />
              )}

              {selectedReportName === "vacateNoticeReport" && (
                <VacateNoticeReport
                  data={reportData}
                  portfolio={selPortfolio}
                />
              )}

              {selectedReportName === "unitDirectoryReport" && (
                <UnitsDirectoryReport
                  data={reportData}
                  property={selProperty}
                />
              )}

              {selectedReportName === "workOrderReport" && (
                <WorkOrdersReport data={reportData} vendor={selVedor} />
              )}

              {selectedReportName === "rentRollReport" && (
                <RentRollsReport data={reportData} property={selProperty} />
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default ReportBuilder;

const styles = {
  datePicker: {
    "& .MuiInputBase-input": {
      padding: "8px",
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: "#FFFFFF",
      "&::placeholder": {
        color: "#9E9E9E",
        fontSize: "14px",
      },
    },
    "& .MuiIconButton-root": {
      padding: "8px",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#F1F5F9",
      "&:hover": {
        backgroundColor: "#006699",
      },
    },
    "& .MuiPickersDay-current": {
      color: "#F1F5F9",
      fontWeight: 300,
    },
  },
};
