import React, { useState } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { GetCompleteAddress } from "./FieldDependants";
import { Gallery } from "../CommonWidgets";

// Custom File Tile
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { PDFViewer } from "../../Components/Documents/documents"; // Import your PDFViewer component
import { clickEffect } from "../CommonWidgets";
import { IconInCircle } from "../Icons";
import { PiClipboardText } from "react-icons/pi";

export const TextReviewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden} className={"break-words " + props.className}>
        <p
          id={props.id + "Title"}
          className={
            "text-gray-700 text-sm font-semibold " + props.labelClassName
          }
        >
          {props.title || "-"}
        </p>
        <p
          id={props.id + "Data"}
          className={"text-neutral-400 " + props.dataClassName}
        >
          {props.body || "-"}
        </p>
      </span>
    </>
  );
};

export const FileReviewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden}>
        <p
          id={props.id + "Title"}
          className="text-gray-700 text-sm font-semibold"
        >
          {props.title || "-"}
        </p>
        <p
          id={props.id + "FileName"}
          className={
            `text-neutral-400 bg-neutral-100 p-2 flex rounded-lg w-fit ` +
            props.dataClassName
          }
          onClick={(e) => props.onClick(props)}
        >
          <AiOutlinePaperClip className="w-5 h-6 mr-1" />
          {props.body || "-"}
        </p>
      </span>
    </>
  );
};

export const MultiFileReviewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden}>
        <p
          id={props.id + "Title"}
          className="text-gray-700 text-sm font-semibold"
        >
          {props.title || "-"}
        </p>
        <p
          id={props.id + "FileNames"}
          className="text-neutral-400 bg-neutral-100 p-2 flex rounded-lg w-fit"
        >
          <AiOutlinePaperClip className="w-5 h-6 mr-1" />
          {props.body.map((file) => file.name).join(", ") || "-"}
        </p>
      </span>
    </>
  );
};

export const DetailsTile = (props) => {
  const containerClasses = `${props.className} grid grid-cols-2 gap-2`;
  const labelClasses = `${props.labelClassName}`;
  const dataClasses = `${props.dataClassName}`;

  return (
    <div id={props.id} hidden={props.hidden} className={containerClasses}>
      <span id={props.id + "Title"} className={labelClasses}>
        {props.label || "-"}
      </span>
      <span id={props.id + "Data"} className={dataClasses}>
        {props.data || "-"}
      </span>
    </div>
  );
};

export const DetailsTileTwoCol = (props) => {
  return (
    <>
      <div className={props.className + " grid grid-cols-2 items-center gap-8"}>
        <span className={props.labelClassName}>{props.label}</span>
        <span className={props.dataClassName}>{props.data}</span>
      </div>
    </>
  );
};

export const DetailsTileAttached = (props) => {
  const containerClasses = `${props.className} flex items-center`;
  const labelClasses = `${props.labelClassName} mr-2`;
  const dataClasses = `${props.dataClassName}`;

  return (
    <div id={props.id} hidden={props.hidden} className={containerClasses}>
      <span className={labelClasses}>{props.label}</span>
      <span className={dataClasses}>{props.data}</span>
    </div>
  );
};

//Multi-Detail Tail
export const MultiDetailsTile = (props) => {
  return (
    <div className={`${props.outerClassName} `}>
      {Object.entries(props.details).map(([key, value]) => (
        <div className={`flex ${props.className}`} key={key} id={key}>
          <span className={`w-40 ${props.labelClassName}`}>{key || "-"}</span>
          <span className={`ml-4 ${props.dataClassName} `}>{value || "-"}</span>
        </div>
      ))}
    </div>
  );
};

export const FileDisplay = ({
  file,
  onDelete,
  onDownload,
  fileNameKey,
  fileUrlKey,
}) => {
  const [openPdf, setOpenPdf] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const checkIfImage = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      return true;
    } else return false;
  };

  const checkIfVideo = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (
      fileExtension === "mp4" ||
      fileExtension === "webp" ||
      fileExtension === "ogg" ||
      fileExtension === "webm"
    ) {
      return true;
    } else return false;
  };

  return (
    <div name="Document" className="flex justify-start items-center gap-2">
      <IconInCircle
        icon={<PiClipboardText className="w-5 h-5 text-blue-52" />}
        radius="2rem"
        color="#CEDBEF"
      />

      {file ? (
        <div className="flex justify-between items-center w-full">
          <span
            id={"File"}
            className={
              "text-blue-53 hover:underline cursor-pointer " + clickEffect
            }
            onClick={(e) => {
              if (file[fileNameKey].split(".").pop().toLowerCase() === "pdf") {
                setOpenPdf(true);
              } else if (checkIfImage(file[fileNameKey])) {
                setShowImage(true);
              } else if (checkIfVideo(file[fileNameKey])) {
                setShowVideo(true);
              }
            }}
          >
            {file[fileNameKey]}
          </span>
          <span className="flex gap-1 items-center">
            <RiDeleteBin5Line
              id="FileDelete"
              className={
                "w-5 h-5 cursor-pointer hover:text-red-10 " + clickEffect
              }
              onClick={() => onDelete(file)}
            />
            <FiDownload
              id="FileDownload"
              className={
                "w-5 h-5 cursor-pointer hover:text-blue-53 " + clickEffect
              }
              onClick={() => onDownload(file)}
            />
          </span>
        </div>
      ) : (
        <p className="mx-auto">Something wrong with File!</p>
      )}

      {showImage && (
        <Gallery
          images={file ? [file[fileUrlKey]] : ""}
          openGallery={showImage}
          setOpenGallery={setShowImage}
        />
      )}

      {showVideo && (
        <Gallery
          videos={file ? [file[fileUrlKey]] : ""}
          openGallery={showVideo}
          setOpenGallery={setShowVideo}
        />
      )}

      {openPdf && (
        <PDFViewer
          pdfUrl={file ? file[fileUrlKey] : ""} // Pass the file URL to PDFViewer if available
          onClose={(e) => setOpenPdf(false)}
        />
      )}
    </div>
  );
};

export const AddressViewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden} className={"break-words " + props.className}>
        <p
          id={props.id + "Title"}
          className={
            "text-gray-700 text-sm font-semibold " + props.labelClassName
          }
        >
          {props.title || "-"}
        </p>
        <p
          id={props.id + "Data"}
          className={"text-neutral-400 " + props.dataClassName}
        >
          {props.body || "-"}
          <p
            id={props.id + "Data2"}
            className={"text-neutral-400 " + props.dataClassName2}
          >
            {props.body2 || ""}
          </p>
        </p>
      </span>
    </>
  );
};
