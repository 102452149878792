const Footer = ({ className }) => {
  return (
    <>
      <span
        className={
          className +
          ` flex justify-center items-center text-gray-500/100 w-full bg-transparent my-4`
        }
        id="yvooaRightsFooter"
        name="footer-text"
        data-cy="footer-text"
      >
        © YVOOA 2023 All Rights Reserved.
        <a
          href="https://www.Yvooa.com/privacyPolicy.html"
          id="yvooaRightLink"
          className="text-blue-600 underline ml-2"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="privacy-policy-link"
        >
          Privacy Policy
        </a>
      </span>
    </>
  );
};

export default Footer;
