import React, { useState, useEffect, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import { Dialog, DialogContent, Stepper, StepLabel, Step } from "@mui/material";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import InputField, {
  InputPhoneNumField,
  InputSelectField,
  InputMultiSelectField,
} from "../../Widgets/Forms/InputFields";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import AddressComponent from "../../Widgets/Forms/AddressComponent";
import { userData, vendorId } from "../../atoms";
import swal from "sweetalert";
import { useRecoilValue } from "recoil";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const AddVendor = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const currentUserData = useRecoilValue(userData);
  const [vendorTypes, setVendorTypes] = useState("");
  const [formData, setFormData] = useState({
    companyName: editForm ? props.data.companyName : "",
    firstName: editForm ? props.data.firstName : "",
    lastName: editForm ? props.data.lastName : "",
    emailId: editForm ? props.data.email : "",
    vendorType: editForm ? props.data.vendorType : "",
  });
  const [areaCovered, setAreaCovered] = useState([]);

  const [contactNo, setContactNo] = useState(
    editForm ? props.data.contactNo : ""
  );
  const [completeAddress, setCompleteAddress] = useState({
    address1: editForm ? props.data.address1 : "",
    address2: editForm ? props.data.address2 : "",
    city: editForm ? props.data.city : "",
    state: editForm ? props.data.state : "",
    country: editForm ? props.data.country : "",
    zipCode: editForm ? props.data.zipCode : "",
  });
  const currentVendorId = useRecoilValue(vendorId);

  useEffect(() => {
    getVendorTypes();
    console.log(props.operation);
    console.log(currentVendorId, "currrrrrr");
  }, []);

  const names = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const getVendorTypes = () => {
    webApi
      .get("/vendor/get/vendorTypes")
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setVendorTypes(res.data.type);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Unable to load Vendor Types, Please try again later.",
            "error"
          );
        }
      });
  };

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    //let Number = formData.contactNo.replace(/[^\d]/g, "");
    const errors = {};
    if (!formData.companyName) {
      errors.companyName = "Company Name is required!";
    }
    if (!contactNo) {
      errors.contactNo = "Contact No is required!";
    } else if (contactNo.length !== 12) {
      errors.contactNo = "Contact number is not valid!";
    }
    if (!formData.firstName) {
      errors.firstName = "First Name is required!";
    }
    if (!formData.lastName) {
      errors.lastName = "Last Name is required!";
    }
    if (!formData.emailId) {
      errors.emailId = "Email is required!";
    } else if (!emailRegex.test(formData.emailId)) {
      errors.emailId = "Email is not valid!";
    }
    if (!formData.vendorType) {
      errors.vendorType = "Vendor Type is required!";
    }
    if (!completeAddress.address1) {
      errors.address1 = "Address 1 is required!";
    }
    if (!completeAddress.city) {
      errors.city = "City is required!";
    }
    if (!completeAddress.state) {
      errors.state = "State is required!";
    }
    if (!completeAddress.country) {
      errors.country = "Country is required!";
    }
    if (!completeAddress.zipCode) {
      errors.zipCode = "Zipcode is required!";
    } else if (completeAddress.zipCode.toString().length !== 5) {
      errors.zipCode = "Zipcode is not valid!";
    }
    return errors;
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      companyName: formData.companyName,
      contactNo: contactNo,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.emailId,
      vendorType: formData.vendorType,
      address1: completeAddress.address1,
      address2: completeAddress.address2,
      city: completeAddress.city,
      state: completeAddress.state,
      country: completeAddress.country,
      zipCode: completeAddress.zipCode,
      areaCovered: areaCovered.join(", "),
      companyId: currentUserData.companyID,
    };

    if (editForm) {
      webApi
        .put("/vendor/" + currentVendorId, data)
        .then(async (res) => {
          if (res.data.errorCode) {
            if (res.data.errorCode === 500) {
              swal(
                "Error",
                "Given contact number exits, please try again!!",
                "error"
              );
            } else {
              swal("Error", res.data.errorMessage, "error");
            }
          } else {
            props.getVendorData();
            swal("Success", "Vendor Updated Successfully", "success");
            props.setOpenVendor(false);
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Update Vendor, Please try again later.`,
              "error"
            );
          }
        });
    } else {
      webApi
        .post("/vendor", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            if (res.data.errorCode === 500) {
              swal(
                "Error",
                "Given contact number exits, please try again!!",
                "error"
              );
            } else {
              swal("Error", res.data.errorMessage, "error");
            }
          } else {
            props.getVendorData();
            swal("Success", "Vendor Added Created", "success");
            props.setOpenVendor(false);
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Create Vendor ${data.firstName}, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const setNewAddress = useCallback((newAddress) => {
    setCompleteAddress(newAddress);
  }, []);

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleOnChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return props.openAddVendor ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Vendor Form"
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-full mx-auto"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {" "}
            {editForm ? "Edit" : "Add"} Vendor
          </span>
          <ButtonIcon
            title="Close"
            onClick={(e) => props.setOpenVendor(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Vendor Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Review</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <section className="flex flex-col justify-center items-center w-full">
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-4/6 md:w-5/6">
                  Vendor Details
                </span>
                <div className="grid grid-cols-2 gap-x-16 gap-y-6 w-4/6 mb-4 md:w-5/6">
                  <InputField
                    label="Company Name"
                    name="companyName"
                    id="companyName"
                    dataCy="companyName"
                    type="text"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={(e) =>
                      handleOnChange("companyName", e.target.value)
                    }
                    errorText={formErrors.companyName}
                    style="!w-full"
                  />

                  <InputPhoneNumField
                    label="Contact Number"
                    name="contactNo"
                    id="contactNo"
                    dataCy="contactNo"
                    placeholder="Enter Contact No."
                    value={contactNo}
                    setValue={setContactNo}
                    errorText={formErrors.contactNo}
                    style="!w-full"
                  />

                  <InputField
                    label="First Name"
                    name="firstName"
                    id="firstName"
                    dataCy="firstName"
                    type="text"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={(e) =>
                      handleOnChange("firstName", e.target.value)
                    }
                    errorText={formErrors.firstName}
                    style="!w-full"
                  />

                  <InputField
                    label="Last Name"
                    name="lastName"
                    id="lastName"
                    dataCy="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={(e) => handleOnChange("lastName", e.target.value)}
                    errorText={formErrors.lastName}
                    style="!w-full"
                  />

                  <InputField
                    label="Email ID"
                    name="emailId"
                    id="emailId"
                    dataC="emailId"
                    type="text"
                    placeholder="abc@example.com"
                    value={formData.emailId}
                    onChange={(e) => handleOnChange("emailId", e.target.value)}
                    errorText={formErrors.emailId}
                    style="!w-full"
                  />

                  <InputSelectField
                    label="Vendor Category"
                    name="vendorType"
                    id="vendorType"
                    dataCy="vendorType"
                    placeholder="Select a Vendor Type"
                    options={vendorTypes}
                    value={formData.vendorType}
                    onChange={(e) =>
                      handleOnChange("vendorType", e.target.value)
                    }
                    errorText={formErrors.vendorType}
                    style="!w-full"
                  />
                </div>

                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-4/6 md:w-5/6">
                  Address Details
                </span>
                <div className="grid grid-cols-2 gap-x-16 gap-y-6 w-4/6 md:w-5/6">
                  <AddressComponent
                    address={completeAddress}
                    setNewAddress={setNewAddress}
                    formErrors={formErrors}
                    style="!w-full"
                  />

                  <InputMultiSelectField
                    label="Area Covered"
                    name="areaCovered"
                    id="areaCovered"
                    dataCy="areaCovered"
                    placeholder="Select a State"
                    options={names}
                    value={areaCovered}
                    setValue={setAreaCovered}
                    errorText={formErrors.tags}
                    style="!w-full"
                    notImp
                  />
                </div>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={() => props.setOpenVendor(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="next"
                    btnName="next"
                    dataCy="next"
                    name="Next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </section>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-3/4">
                  Review Vendor Details
                </span>
                <section className="grid grid-cols-3 gap-x-16 gap-y-6 w-3/4 justify-around items-center">
                  <TextReviewTile
                    title="Company Name"
                    body={formData.companyName}
                  />
                  <TextReviewTile
                    title="Contact Number"
                    body={PhoneNumFormat(contactNo, true)}
                  />
                  <TextReviewTile
                    title="First Name"
                    body={formData.firstName}
                  />
                  <TextReviewTile title="Last Name" body={formData.lastName} />
                  <TextReviewTile title="Email ID" body={formData.emailId} />
                  <TextReviewTile
                    title="Vendor Type"
                    body={formData.vendorType}
                  />
                </section>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-3/4">
                  Review Address Details
                </span>
                <section className="grid grid-cols-3 gap-x-16 gap-y-6 w-3/4 justify-around items-center">
                  <TextReviewTile
                    title="Address 1"
                    body={completeAddress.address1}
                  />
                  <TextReviewTile
                    title="Address 2"
                    body={completeAddress.address2}
                  />
                  <TextReviewTile title="City" body={completeAddress.city} />
                  <TextReviewTile title="State" body={completeAddress.state} />
                  <TextReviewTile
                    title="Zip Code"
                    body={completeAddress.zipCode}
                  />
                  <TextReviewTile
                    title="Area Covered"
                    body={areaCovered.join(", ")}
                  />
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="back"
                    btnName="back"
                    dataCy="back"
                    name="Back"
                    onClick={(e) => handleBack(e)}
                  />
                  <ButtonBlue
                    name="Confirm"
                    id="confirm"
                    btnName="confirm"
                    dataCy="confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default AddVendor;
