import React, { useState } from "react";
import {
  GetDateInMMDDYYYY,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { ButtonIcon } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import ExportCSV from "../../Widgets/ExportCSV";
import { DownloadRentalLedgerPdfAPI } from "./reportsAPI";

export const RentalLedgerReport = (props) => {
  const [tableData, setTableData] = useState(props.data.ledgers || []);
  const ledgerTotals = {
    rentalBalance: 0,
    prepayBalance: 0,
  };

  const downloadPDF = () => {
    let requestData = {
      tenantCode: props.tenant.tenantCode,
      rentalRequest: {
        startDate: props.startDate,
        endDate: props.endDate,
      },
    };

    DownloadRentalLedgerPdfAPI(requestData).then((res) => {
      // const link = document.createElement("a");
      // link.href = res.data.url;
      // link.setAttribute(
      //   "download",
      //   `${props.tenant.tenantName}rentalLedger.pdf`
      // ); // You can set a default filename
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      window.open(res.data.url, "_blank");
    });
  };

  return (
    <section id="rentalLedgerReport">
      <section className="flex justify-between items-center mb-2 mx-2">
        <div className="flex items-center gap-4">
          <span className="text-gray-400/100 flex items-center gap-1">
            <span className="inline-block w-2 h-2 rounded-full bg-green-500 mr-2"></span>
            Prepayment
            <p className="font-semibold text-black text-xl">
              {ledgerTotals.prepayBalance.toString().startsWith("-")
                ? "-$" +
                  ledgerTotals.prepayBalance.toLocaleString().substring(1)
                : "$" + ledgerTotals.prepayBalance.toLocaleString()}
            </p>
          </span>

          <span className="text-gray-400/100 flex items-center gap-1">
            <span className="inline-block w-2 h-2 rounded-full bg-red-500 mr-2"></span>
            Balance
            <p className="font-semibold text-black text-xl">
              {ledgerTotals.rentalBalance.toString().startsWith("-")
                ? "-$" +
                  ledgerTotals.rentalBalance.toLocaleString().substring(1)
                : "$" + ledgerTotals.rentalBalance.toLocaleString()}
            </p>
          </span>
        </div>

        <span className="flex justify-end items-center gap-4">
          <ButtonIcon
            id="Download"
            btnName="download"
            dataCy="download"
            title="Download"
            onClick={downloadPDF}
            icon={<BsDownload className="w-5 h-5" />}
            className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
          />

          <ExportCSV
            data={tableData}
            keys={[
              {
                key: "dateOccured",
                title: "Date",
                //applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
              },
              { key: "description", title: "Description" },
              {
                key: "paymentInfo",
                title: "Payment Type",
              },
              {
                key: "paymentAmount",
                title: "Amount",
                applyMethod: (row) => {
                  if (row.paymentInfo === "Payment" && row.paymentAmount) {
                    return "- $" + row.paymentAmount;
                  } else {
                    return "$" + row.paymentAmount;
                  }
                },
              },
              {
                key: "balance",
                title: "Balance",
                applyMethod: (row) => {
                  if (row.paymentInfo === "Prepayment") {
                    return `-`;
                  } else {
                    if (row.balance.toString().startsWith("-")) {
                      return "-$" + row.balance.substring(1);
                    } else {
                      return "$" + row.balance;
                    }
                  }
                },
              },
            ]}
            fileName="Rental Ledger.csv"
            className={"bg-white"}
          />

          <Searchbar
            placeholder="Search..."
            data={props.data.ledgers || []}
            matchProps={[
              { date: "dateOccured" },
              { text: "description" },
              { text: "paymentAmount" },
              { text: "paymentInfo" },
              { text: "balance" },
            ]}
            setTableData={setTableData}
          />
        </span>
      </section>

      <TableComponent
        tableId="ledgerTable"
        className={"bg-white"}
        data={tableData}
        columns={[
          {
            key: "dateOccured",
            title: "Date",
            applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
          },
          { key: "description", title: "Description" },
          {
            key: "paymentInfo",
            title: "Payment Type",
            getCellStyles: (row) => {
              if (row.paymentInfo === "Charge") {
                return { color: "red" };
              }
            },
          },
          {
            key: "paymentAmount",
            title: "Amount",
            applyMethod: (row) => {
              if (row.paymentInfo === "Payment" && row.paymentAmount) {
                return "-$" + row.paymentAmount.toLocaleString();
              } else {
                return "$" + row.paymentAmount.toLocaleString();
              }
            },
            getCellStyles: (row) => {
              if (row.paymentInfo === "Charge") {
                return { color: "red" };
              }
            },
          },

          {
            key: "balance",
            title: "Balance",
            applyMethod: (row) => {
              if (row.paymentInfo === "Prepayment") {
                return `-`;
              } else {
                if (row.balance.toString().startsWith("-")) {
                  return "-$" + row.balance.toLocaleString().substring(1);
                } else {
                  return "$" + row.balance.toLocaleString();
                }
              }
            },
          },
        ]}
        rowOnClick={(row) => {}}
        //initialSort={{ key: "dateOccured", direction: "descending" }}
      />
    </section>
  );
};
