import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clientId, companyId } from "../../atoms";
import { useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import { ButtonIcon } from "../../Widgets/Buttons";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import {
  ButtonCustom,
  ButtonGrayOutline,
  ButtonDelete,
} from "../../Widgets/Buttons";
import {
  TableComponent,
  Searchbar,
  GetDateInMMDDYYYY,
} from "../../Widgets/CommonWidgets";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { BsPlusCircle } from "react-icons/bs";
import swal from "sweetalert";
import { IconInCircle, InfoIcon } from "../../Widgets/Icons";
import { TbFileSettings } from "react-icons/tb";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";
import ExportCSV from "../../Widgets/ExportCSV";
import { ButtonBlueOutline } from "../../Widgets/Buttons";
import { Dialog, DialogContent } from "@mui/material";
import { ButtonBlue } from "../../Widgets/Buttons";
import CreateMaintenanceRequest from "./CreateMaintenanceRequest";

const MaintenancaDashboard = (props) => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [allPortfolios, setAllPortfolios] = useState([]);

  const [allMaintenanceTicketsMap, setAllMaintenanceTicketsMap] = useState(
    new Map()
  );
  const [maintenanceTableData, setMaintenanceTableData] = useState([]);
  const [allPropertiesMap, setAllPropertiesMap] = useState(new Map());
  const [properties, setProperties] = useState([]);
  const [allUnitsMap, setAllUnitsMap] = useState(new Map());
  const [units, setUnits] = useState([]);
  const [allVendorsMap, setAllVendorsMap] = useState(new Map());
  const [vendors, setVendors] = useState([]);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [openMaintenanceForm, setOpenAddMaintenanceForm] = useState("")
  const [openMaintenanceReqPage, setOpenMaintenanceReqPage] = useState(false)
  const [unitIdFromAddNew, setUnitIdFromAddNew] = useState("")

  useEffect(() => {
    getPortfolios();
    getAllMaintenanceTickets();
  }, []);

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const ports =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data;
          setAllPortfolios([{ portfolioName: "ALL", ID: 0 }, ...ports]);
          setSelectedPortfolioID(0);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllMaintenanceTickets = () => {
    webApi
      .get(`/maintenance/tickets/client/${clientIdVal}/tickets`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setMaintenanceTableData(res.data.reverse());
          setAllMaintenanceTicketsMap(
            allMaintenanceTicketsMap.set(0, res.data)
          );
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get all Maintenance Work Orders, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handlePortfolioChange = (portfolioId) => {
    setSelectedPortfolioID(portfolioId);
    setSelectedPropertyId("");
    if (allMaintenanceTicketsMap.has(portfolioId)) {
      setMaintenanceTableData(allMaintenanceTicketsMap.get(portfolioId));
      if (portfolioId) {
        getProperties(portfolioId);
      }
      return;
    }
    webApi
      .get("/maintenance/module/portfolio/" + portfolioId + "/tickets")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setMaintenanceTableData(res.data);
          setAllMaintenanceTicketsMap(
            allMaintenanceTicketsMap.set(portfolioId, res.data)
          );
          getProperties(portfolioId);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Maintenance Work Orders of Selected Portfolio, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId) => {
    if (allPropertiesMap.has(portfolioId)) {
      setProperties(allPropertiesMap.get(portfolioId));
      return;
    }
    setProperties([]);
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const acsendingData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          setProperties(acsendingData);
          setAllPropertiesMap(allPropertiesMap.set(portfolioId, acsendingData));
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Properties of Selected portfolio, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handlePropertyChange = (propertyId) => {
    setSelectedUnitId("");
    setSelectedPropertyId(parseInt(propertyId));
    if (allUnitsMap.has(propertyId)) {
      setUnits(allUnitsMap.get(propertyId));
      return;
    }
    setUnits([]);
    webApi
      .get("/units/property/" + propertyId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const ascendingData =
            res.data.length > 1
              ? res.data.sort((a, b) => (a.unitNumber > b.unitNumber ? 1 : -1))
              : res.data;
          setUnits(ascendingData);
          setAllUnitsMap(allUnitsMap.set(propertyId, ascendingData));
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Units of Selected Property, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleUnitChange = (unitId) => {
    setSelectedVendor("");
    setSelectedUnitId(unitId);
    if (allVendorsMap.has(unitId)) {
      setVendors(allVendorsMap.get(unitId));
      return;
    }
    setVendors([]);
    webApi
      .get(`/vendor/unit/${unitId}/vendors`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const ascendingData =
            res.data.length > 1
              ? res.data.sort((a, b) => (a.vendorName > b.vendorName ? 1 : -1))
              : res.data;
          setVendors(ascendingData);
          setAllVendorsMap(allVendorsMap.set(unitId, ascendingData));
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Vendors of Selected Unit, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleAdvancedSearch = (e) => {
    e.preventDefault();
    if (allMaintenanceTicketsMap.has(selectedPortfolioID)) {
      const portsMaintenanceTickets =
        allMaintenanceTicketsMap.get(selectedPortfolioID);

      let matchProps = [];
      if (selectedPropertyId) {
        matchProps.push({ key: "propertyId", value: selectedPropertyId });
      }
      if (selectedUnitId) {
        matchProps.push({ key: "unitID", value: selectedUnitId });
      }
      if (selectedVendor) {
        matchProps.push({ key: "vendor", value: selectedVendor });
      }

      if (matchProps.length) {
        const filteredData = portsMaintenanceTickets.filter((item) =>
          matchProps.every((prop) =>
            item[prop.key].toString().includes(prop.value)
          )
        );
        setMaintenanceTableData(filteredData);
      } else {
        setMaintenanceTableData(portsMaintenanceTickets);
      }
    } else {
      swal("Failure", "Something went wrong", "error");
    }
  };

  const handleClearAll = (e) => {
    e.preventDefault();
    if (selectedPropertyId || selectedUnitId || selectedVendor) {
      setSelectedPropertyId("");
      setSelectedUnitId("");
      setSelectedVendor("");
      setUnits([]);
      setMaintenanceTableData(
        allMaintenanceTicketsMap.get(selectedPortfolioID)
      );
    }
  };

  return (
    <>
     {openMaintenanceForm && (
        <AddMaintenanceForm
          trigger={openMaintenanceForm}
          setTrigger={setOpenAddMaintenanceForm}
          allPortfolios={allPortfolios}
          clientId={clientIdVal}
          unitId={setUnitIdFromAddNew}
          openMaintenaceReqFrom={setOpenMaintenanceReqPage}
        />
      )}

      {openMaintenanceReqPage && (
        <CreateMaintenanceRequest
          trigger={openMaintenanceReqPage}
          setTrigger={setOpenMaintenanceReqPage}
          unitId={unitIdFromAddNew}
          getMaintenanceTickets={() => {
            getAllMaintenanceTickets();
          }}
        />
      )}
      <div className="w-5/6 m-auto">
        <span
          id="maintenance-header-text"
          className="flex justify-center items-center mb-12 bg-blue-52 text-white font-semibold text-center rounded h-10"
        >
          Maintenance Module
          <InfoIcon message="Use the advanced Maintenance filter System to track work orders, request status, and much more!" />
        </span>

        <section
          name="Header and Select Portfolio"
          className="flex flex-col mb-6"
        >
          <div className="flex justify-start items-center mb-6">
            <IconInCircle
              icon={
                <TbFileSettings
                  id="maintenance-icon"
                  className="w-9 h-9 text-[#2C88AE]"
                />
              }
              radius="4rem"
              className="shadow-xl mx-6"
              id="maintenance-icon-circle"
            />
            <span>
              <p id="view-maintenance-text" className="font-semibold">
                View All Your Maintenance Orders!
              </p>
              <p id="advanced-maintenance-text" className="text-gray-400/100">
                Use the advanced Maintenance filter System to track work orders,
                request status and much more!
              </p>
            </span>
          </div>

          {!showAdvancedSearch && (
            <div className="flex justify-between items-end mb-4 mx-6 gap-4">
              <div className="flex justify-start items-end gap-4">
                <InputObjectSelectField
                  label="Select Portfolio"
                  name="portfolio"
                  id="portfolio"
                  dataCy="portfolio"
                  placeholder="Select a Portfolio"
                  value={selectedPortfolioID}
                  onChange={(e) =>
                    handlePortfolioChange(parseInt(e.target.value))
                  }
                  options={allPortfolios}
                  optionValue="ID"
                  optionName="portfolioName"
                  style="rounded-md !w-auto"
                />
                {selectedPortfolioID !== 0 && selectedPortfolioID !== "" && (
                  <ButtonGrayOutline
                    id="advancedSearch"
                    dataCy="advancedSearch"
                    title="Advanced Search"
                    name={
                      <span className="flex whitespace-nowrap">
                        <AiOutlineSearch
                          size={25}
                          className="cursor-pointer mr-[4px]"
                        />
                        Advanced Search
                      </span>
                    }
                    onClick={() => setShowAdvancedSearch(true)}
                    className="!w-auto overflow-clip"
                  />
                )}
              </div>
              <div className="flex flex-row gap-2">
                <ExportCSV
                  data={maintenanceTableData}
                  keys={[
                    { key: "woid", title: "Work Order ID" },
                    { key: "category", title: "Category" },
                    { key: "portfolioName", title: "Portfolio Name" },
                    { key: "propertyName", title: "Property Name" },
                    { key: "unitNumber", title: "Unit Number" },
                    { key: "vendor", title: "Vendor" },
                    {
                      key: "status",
                      title: "Status",
                    },
                  ]}
                  fileName="Maintenance.csv"
                />
                <ButtonBlueOutline
                      id="addNew"
                      btnName="addNew"
                      dataCy="addNew"
                      name={
                        <>
                          <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                        </>
                      }
                      onClick={(e) => {
                        setOpenAddMaintenanceForm(true)
                      }}
                      className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
                    />
                <Searchbar
                  placeholder="Search Maintenance Table..."
                  data={allMaintenanceTicketsMap.get(selectedPortfolioID)}
                  matchProps={[
                    { text: "woid" },
                    { text: "requestType" },
                    { text: "category" },
                    { text: "portfolioName" },
                    { text: "propertyName" },
                    { text: "unitNumber" },
                    { text: "taskAssigned" },
                    { text: "vendor" },
                    { text: "status" },
                    { date: "CreatedAt" },
                    { date: "UpdatedAt" },
                  ]}
                  setTableData={setMaintenanceTableData}
                  style="!w-auto"
                  id="maintenance-searchbar"
                />
              </div>
            </div>
          )}

          {showAdvancedSearch && (
            <div
              id="advancedSearchHeading"
              name="Advanced Search"
              className="grid grid-cols-2 items-center gap-4 mx-6 mb-8 px-6 py-4 bg-slate-100 rounded"
            >
              <span
                id="advanced-maintenance-search-text"
                className="font-semibold text-base"
              >
                Advanced Search
              </span>
              <ButtonDelete
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name={
                  <span
                    id="cancel-advanced-search-text"
                    className="flex justify-center items-center hover:font-semibold"
                  >
                    Back
                    <AiOutlineCloseCircle
                      id="cancel-advanced-search-icon"
                      className="w-5 h-5 ml-[4px]"
                    />
                  </span>
                }
                onClick={(e) => {
                  setShowAdvancedSearch(false);
                  handleClearAll(e);
                }}
                className="!h-8 justify-self-end"
              />

              <DetailsTile
                label="Select Property"
                data={
                  <InputObjectSelectField
                    //label="Select Property"
                    name="property"
                    id="property"
                    dataCy="property"
                    notImp
                    placeholder="Select a property"
                    value={selectedPropertyId}
                    onChange={(e) => handlePropertyChange(e.target.value)}
                    options={properties}
                    optionValue="ID"
                    optionName="propertyName"
                    style=""
                  />
                }
                labelClassName="flex items-center justify-self-end"
              />

              <DetailsTile
                label="Select Unit"
                data={
                  <InputObjectSelectField
                    //label="Select Unit"
                    name="unit"
                    id="unit"
                    dataCy="unit"
                    notImp
                    placeholder="Select a Unit"
                    value={selectedUnitId}
                    onChange={(e) => handleUnitChange(parseInt(e.target.value))}
                    options={units}
                    optionValue="ID"
                    optionName="unitNumber"
                  />
                }
                labelClassName="flex items-center justify-self-end"
              />

              {/* <DetailsTile
                label="Tags"
                data={
                  <InputObjectSelectField
                    //label="Tags"
                    name="tags"
                    id="tags"
                    notImp
                    disabled
                    placeholder="tags"
                    value={""}
                    onChange={(e) => {}}
                    options={[]}
                    optionValue="ID"
                    optionName="portfolioName"
                    style=""
                    className="bg-gray-50"
                    //hintText="Disabled"
                  />
                }
                labelClassName="justify-self-end mr-[12px]"
              /> */}

              <DetailsTile
                label="Select Vendor"
                data={
                  <InputObjectSelectField
                    //label="Select Vendor"
                    name="vendor"
                    id="vendor"
                    dataCy="vendor"
                    notImp
                    placeholder="Select a Vendor"
                    value={selectedVendor}
                    onChange={(e) => setSelectedVendor(e.target.value)}
                    options={vendors}
                    optionValue="vendorName"
                    optionName="vendorName"
                    style=""
                    className="bg-gray-50"
                  />
                }
                labelClassName="flex items-center justify-self-end"
              />

              {/* This is a Dummy Div to push buttons to next column, Dont Remove
              <div></div> */}
              <div className="flex justify-end items-end gap-4 mr-[12px]">
                <ButtonCustom
                  id="clearAll"
                  btnName="celarAll"
                  dataCy="celarAll"
                  name="Clear All"
                  onClick={(e) => handleClearAll(e)}
                  className="px-2 py-1 text-center w-24 bg-transparent rounded border-2 text-gray-600"
                />
                <ExportCSV
                  data={maintenanceTableData}
                  keys={[
                    { key: "woid", title: "Work Order ID" },
                    { key: "requestType", title: "Request Type" },
                    { key: "category", title: "Category" },
                    {
                      key: "CreatedAt",
                      title: "Date of Request",
                    },
                    { key: "portfolioName", title: "Portfolio Name" },
                    { key: "propertyName", title: "Property Name" },
                    { key: "unitNumber", title: "Unit Number" },
                    {
                      key: "UpdatedAt",
                      title: "Last Updated",
                    },
                    { key: "taskAssigned", title: "Assigned To" },
                    { key: "vendor", title: "Vendor" },
                    {
                      key: "status",
                      title: "Status",
                    },
                  ]}
                  fileName="Maintenance.csv"
                />
                <ButtonCustom
                  id="search"
                  btnName="advanceSearch"
                  dataCy="advanceSearch"
                  name="Search"
                  onClick={(e) => handleAdvancedSearch(e)}
                  className="px-2 py-1 text-center w-24 text-white bg-[#FFAF09]"
                />
              </div>
            </div>
          )}
        </section>

        <section name="maintenanceTable">
          <TableComponent
            tableId="maintenanceTable"
            data={maintenanceTableData}
            columns={[
              { key: "woid", title: "Work Order ID" },
              { key: "requestType", title: "Request Type" },
              { key: "category", title: "Category" },
              {
                key: "CreatedAt",
                title: "Date of Request",
                applyMethod: (row) => GetDateInMMDDYYYY(row.createdAt),
              },
              { key: "portfolioName", title: "Portfolio Name" },
              { key: "propertyName", title: "Property Name" },
              { key: "unitNumber", title: "Unit Number" },
              {
                key: "UpdatedAt",
                title: "Last Updated",
                applyMethod: (row) => GetDateInMMDDYYYY(row.updatedAt),
              },
              { key: "taskAssigned", title: "Assigned To" },
              { key: "vendor", title: "Vendor" },
              {
                key: "status",
                title: "Status",
                dataClassName: "font-medium",
                // getCellStyles: (row) => {
                //   return { color: row.statusColor };
                // },
                //applyMethod: (row) => changetoUpperCase(status),
              },
            ]}
            rowOnClick={(row) =>
              navigate("/maintenance/maintenanceDetails", {
                state: { ticketId: row.maintenanceId },
              })
            }
            //initialSort={{ key: "CreatedAt", direction: "descending" }}
          />
        </section>
      </div>
    </>
  );
};

export default MaintenancaDashboard;

export const AddMaintenanceForm = (props) => {
  const webApi = new WebApimanager();
  const [formErrors, setFormErrors] = useState({});
  const [selectionState, setSelectionState] = useState({
    portfolioID: null,
    propertyID: null,
    unitID: null,
    propertiesOptions: [],
    unitsOptions: [],
    allPortfolios: [],
    allPropertiesMap: new Map(),
    allUnitsMap: new Map(),
    viewUnitField: false,
  });

  useEffect(() => {
    getPortfolios();
  }, []);

  const handleError = (error) => {
    const errorMessage = error.customErrorMessage || "Oops, something went wrong. Please try again later.";
    swal("Error", errorMessage, "error");
  };

  const getPortfolios = async () => {
    try {
      const res = await webApi.get(`/portfolios/client/${props.clientId}`);
      if (res.data.errorCode) {
        swal("Failure", res.data.errorMessage, "error");
      } else {
        const sortedPortfolios = res.data.sort((a, b) =>
          a.portfolioName.localeCompare(b.portfolioName)
        );
        setSelectionState((prev) => ({ ...prev, allPortfolios: sortedPortfolios }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getProperties = async (portfolioId) => {
    const { allPropertiesMap } = selectionState;
    if (allPropertiesMap.has(portfolioId)) {
      setSelectionState((prev) => ({
        ...prev,
        propertiesOptions: allPropertiesMap.get(portfolioId),
      }));
      return;
    }

    try {
      const res = await webApi.get(`/properties/portfolio/${portfolioId}`);
      if (res.data.errorCode) {
        swal("Failure", res.data.errorMessage, "error");
      } else {
        const sortedProperties = res.data.sort((a, b) =>
          a.propertyName.localeCompare(b.propertyName)
        );
        setSelectionState((prev) => {
          const newPropertiesMap = new Map(allPropertiesMap);
          newPropertiesMap.set(portfolioId, sortedProperties);
          return {
            ...prev,
            propertiesOptions: sortedProperties,
            allPropertiesMap: newPropertiesMap,
          };
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getAllUnits = async (propertyId, type) => {
    const { allUnitsMap } = selectionState;
    if (allUnitsMap.has(propertyId)) {
      setSelectionState((prev) => ({
        ...prev,
        unitsOptions: allUnitsMap.get(propertyId),
      }));
      return;
    }

    try {
      const res = await webApi.get(`/units/property/${propertyId}`);
      if (res.data.errorCode) {
        swal("Failure", res.data.errorMessage, "error");
      } else {
        const sortedUnits = res.data.sort((a, b) =>
          a.unitNumber.localeCompare(b.unitNumber)
        );
        setSelectionState((prev) => {
          const newUnitsMap = new Map(allUnitsMap);
          newUnitsMap.set(propertyId, sortedUnits);
          return {
            ...prev,
            unitsOptions: sortedUnits,
            allUnitsMap: newUnitsMap,
            unitID: type === "singleFam" ? res.data[0].ID : prev.unitID,
          };
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const validate = () => {
    const errors = {};
    const { portfolioID, propertyID, unitID, viewUnitField } = selectionState;

    if (!portfolioID) errors.portfolioId = "Portfolio is required!";
    if (!propertyID) errors.propertyId = "Property is required!";
    if (viewUnitField && !unitID) errors.unitId = "Unit is required!";
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    setFormErrors(errors);
    if (Object.keys(errors).length) return;

    props.setTrigger(false);
    props.openMaintenaceReqFrom(true);
    props.unitId(selectionState.unitID);
  };

  const handlePortfolioSelection = (e) => {
    const selectedPortfolioID = parseInt(e.target.value);
    setSelectionState((prev) => ({
      ...prev,
      portfolioID: selectedPortfolioID,
      propertiesOptions: [],
      propertyID: null,
      unitsOptions: [],
    }));

    getProperties(selectedPortfolioID);

    const selectedPortfolio = selectionState.allPortfolios.find(
      (portfolio) => portfolio.ID === selectedPortfolioID
    );

    setSelectionState((prev) => ({
      ...prev,
      viewUnitField: selectedPortfolio?.portfolioType === 'Multi-Family',
    }));
  };

  const handlePropertySelection = (e) => {
    const selectedPropertyID = parseInt(e.target.value);
    setSelectionState((prev) => ({
      ...prev,
      propertyID: selectedPropertyID,
      unitsOptions: [],
      unitID: null,
    }));

    const selectedProperty = selectionState.propertiesOptions.find(
      (property) => property.ID === selectedPropertyID
    );

    if (selectedProperty?.propertyType === 'Single Family') {
      getAllUnits(selectedPropertyID, "singleFam");
    } else {
      getAllUnits(selectedPropertyID);
    }
  };

  return (
    props.trigger && (
      <Dialog
        open={true}
        aria-labelledby="Add New Maintenance Form"
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { height: "auto" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Create Maintenance Ticket</span>
          <ButtonIcon
            id="close"
            btnName="close"
            dataCy="close"
            title="Close"
            onClick={() => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <DialogContent dividers>
          <div className="flex flex-col justify-center gap-4 m-4">
            <InputObjectSelectField
              label="Select Portfolio"
              name="portfolioName"
              id="portfolioId"
              placeholder="Select Portfolio"
              value={selectionState.portfolioID}
              onChange={handlePortfolioSelection}
              options={selectionState.allPortfolios}
              optionValue="ID"
              optionName="portfolioName"
              className="w-full"
              errorText={formErrors.portfolioId}
            />

            <InputObjectSelectField
              label="Select Property"
              name="property"
              id="property"
              placeholder="Select a property"
              value={selectionState.propertyID}
              onChange={handlePropertySelection}
              options={selectionState.propertiesOptions}
              optionValue="ID"
              optionName="propertyName"
              className="w-full"
              errorText={formErrors.propertyId}
            />
            
            {selectionState.viewUnitField && (
              <InputObjectSelectField
                label="Select Unit"
                name="unit"
                id="unit"
                placeholder="Select Unit"
                value={selectionState.unitID}
                onChange={(e) => setSelectionState((prev) => ({ ...prev, unitID: parseInt(e.target.value) }))}
                options={selectionState.unitsOptions}
                optionValue="ID"
                optionName="unitNumber"
                className="!w-full"
                errorText={formErrors.unitId}
              />
            )}
          </div>

          <div className="flex justify-center items-center gap-16 my-6">
            <ButtonGrayOutline
              id="cancel"
              btnName="cancel"
              dataCy="cancel"
              name="Cancel"
              title="Close the Form"
              onClick={() => props.setTrigger(false)}
              className="justify-self-end"
            />
            <ButtonBlue name="Confirm" onClick={handleSubmit} />
          </div>
        </DialogContent>
      </Dialog>
    )
  );
};

