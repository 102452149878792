import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  accessToken,
  idToken,
  refreshToken,
  clientId,
  userData,
} from "../../atoms";
import { useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import { GoogleChart } from "../../Widgets/charts";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { TableComponent } from "../../Widgets/CommonWidgets";
import { IoIosArrowDropleftCircle, IoIosArrowDown } from "react-icons/io";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  GetCurrentMonth,
  MonthsShortNamesList,
  last12MonthsList,
} from "../../Widgets/Forms/FieldDependants";
import swal from "sweetalert";

const Dashboard = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const clientIdVal = useRecoilValue(clientId);
  const userInfo = useRecoilValue(userData);
  const [portfoliosOverview, setPortfoliosOverview] = useState([]);
  const [maintenanceOverview, setMaintenanceOverview] = useState([]);
  const [expiringLeases, setExpiringLeases] = useState([]);

  const [selectedReportPeriod, setSelectedReportPeriod] = useState(12);

  const [filteredPropRevenue, setFilteredPropRevenue] = useState([
    ["Annual Revenue", "Rent Collected", "Actual Rent"],
    ["Jan", 0, 0],
    ["Feb", 0, 0],
    ["Mar", 0, 0],
    ["Apr", 0, 0],
    ["May", 0, 0],
    ["Jun", 0, 0],
    ["Jul", 0, 0],
    ["Aug", 0, 0],
    ["Sep", 0, 0],
    ["Oct", 0, 0],
    ["Nov", 0, 0],
    ["Dec", 0, 0],
  ]);
  const [propertyRevenue, setPropertyRevenue] = useState([]);
  const [allPortfoliosPropRevenue, setAllPortfoliosPropRevenue] = useState([]);

  const [vendorInvoice, setVendorInvoice] = useState([
    {
      orderNo: "233468",
      transactionId: "T-23456789",
      amount: "$300",
      status: "PENDING",
    },
    {
      orderNo: "233468",
      transactionId: "T-23456789",
      amount: "$300",
      status: "CLOSED",
    },
    {
      orderNo: "233468",
      transactionId: "T-23456789",
      amount: "$300",
      status: "CLOSED",
    },
    {
      orderNo: "233468",
      transactionId: "T-23456789",
      amount: "$300",
      status: "CLOSED",
    },
    {
      orderNo: "233468",
      transactionId: "T-23456789",
      amount: "$300",
      status: "CLOSED",
    },
  ]);

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [properties, setProperties] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");

  useEffect(() => {
    if (userInfo.surveyStatus === false) {
      navigate("/survey");
    } else if (
      userInfo.isSubscribed === false &&
      userInfo.userRole === "Client Admin"
    ) {
      navigate("/subscription");
    }
    webApi
      .get(`/pmCoreDashboard/client/${clientIdVal}/dashboardDetails`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          for (const key in res.data.portfolioOverview) {
            setPortfoliosOverview([
              ["Property Overview", "No. of Units"],
              ["Turnover", res.data.portfolioOverview.Pending],
              [
                "Vacant Ready to Rent",
                res.data.portfolioOverview["Vacant (Ready to Rent)"],
              ],
              [
                "Vacant Not Ready to Rent",
                res.data.portfolioOverview["Vacant Not Ready to Rent"],
              ],
              ["Occupied", res.data.portfolioOverview.Occupied],
            ]);
          }

          for (const key in res.data.maintenanceOverview) {
            setMaintenanceOverview([
              ["Maintenance Status", "No. of WorkOrders"],
              ["Pending", res.data.maintenanceOverview.Pending],
              ["Open", res.data.maintenanceOverview.Open],
              ["In-Progress", res.data.maintenanceOverview["In Progress"]],
              ["Completed", res.data.maintenanceOverview.completed],
            ]);
          }

          setExpiringLeases([
            ["Lease Period", "No. of Expiring Leases"],
            ["<30 Days", res.data.leaseOverview.zeroToThirty],
            ["30-60 Days", res.data.leaseOverview.thirtyToSixty],
            [">60 Days", res.data.leaseOverview.sixtyToNinety],
          ]);

          const tempRev = res.data.revenue;
          const RevenueData = [
            ["Annual Revenue", "Rent Collected", "Actual Rent"],
            ...last12MonthsList.map((month) => {
              const collectedRent = tempRev[month]?.collectedRent ?? 0;
              const actualRent = tempRev[month]?.actualRent ?? 0;
              return [month, collectedRent, actualRent];
            }),
          ];

          setFilteredPropRevenue(RevenueData);
          setPropertyRevenue(RevenueData);
          setAllPortfoliosPropRevenue(RevenueData);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Dashboard Details, Please try again later.`,
            "error"
          );
        }
      });
    getPortfolios();
  }, []);

  const getPropertyRevenueData = (portfolioId, propertyId) => {
    webApi
      .get(
        `/pmCoreDashboard/revenue/client/${clientIdVal}/property/${propertyId}/portfolio/${portfolioId}`
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const tempRev = res.data.revenue;
          const RevenueData = [
            ["Annual Revenue", "Rent Collected", "Actual Rent"],
            ...last12MonthsList.map((month) => {
              const collectedRent = tempRev[month]?.collectedRent ?? 0;
              const actualRent = tempRev[month]?.actualRent ?? 0;
              return [month, collectedRent, actualRent];
            }),
          ];
          setPropertyRevenue(RevenueData);
          setFilteredPropRevenue(RevenueData);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Revenue Details, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data;
          setAllPortfolios(sortedData);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId) => {
    setSelectedPortfolioID(parseInt(portfolioId));
    setSelectedPropertyId("");
    setProperties([]);
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const acsendingData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          if (res.data.length) {
            setProperties([
              { ID: 0, propertyName: "All Properties" },
              ...acsendingData,
            ]);
            setSelectedPropertyId(0);
          } else {
            setProperties(acsendingData);
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Properties, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleFilteredRevenueData = (filtervalue) => {
    let filteredData = [propertyRevenue[0]];

    if (filtervalue === 12) {
      filteredData = propertyRevenue;
    } else if (filtervalue === 6) {
      filteredData = [...filteredData, ...propertyRevenue.slice(-6)];
    } else if (filtervalue === 3) {
      filteredData = [...filteredData, ...propertyRevenue.slice(-3)];
    }

    setFilteredPropRevenue(filteredData);
  };

  const portfolioOverviewChartOptions = {
    //title: "Portfolio Overview",
    // titleTextStyle: {
    //   fontSize: 18,
    // },
    pieHole: 0.4,
    is3D: false,
    colors: ["#3D72BF", "#FF6522", "#38B37E", "#FBB53C"],
    pieSliceText: "value",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { fontSize: 12 },
    },
    tooltip: {
      animation: { startup: true, duration: 0 },
      position: "out",
    },
  };

  const maintenanceOverviewOptions = {
    //title: "Maintenance",
    // titleTextStyle: {
    //   fontSize: 18,
    // },
    pieHole: 0.4,
    is3D: false,
    colors: ["#FBB53C", "#3D72BF", "#FF6522", "#38B37E"],
    pieSliceText: "value",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { fontSize: 12 },
    },
    tooltip: {
      position: "out", // Position the tooltip outside the pie chart
    },
  };

  const expiringLeasesOptions = {
    //title: "Expiring Leases",
    // titleTextStyle: {
    //   fontSize: 18,
    // },
    pieHole: 0.4,
    //pieStartAngle: -90,
    is3D: false,
    colors: ["#D10D22", "#FF6522", "#3D72BF"],
    pieSliceText: "value",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { fontSize: 12 },
    },
  };

  const propertyRevenueOptions = {
    colors: ["#D96470", "#4049AC"],
    legend: {
      position: "right",
      alignment: "start",
    },
  };

  return (
    <>
      <section
        className={`w-11/12 m-auto my-6 grid grid-cols-3 gap-x-4 gap-y-4 justify-between items-center text-center`}
      >
        <ChartCard
          id="propertyOverview"
          title="Properties Overview"
          cardClassName="col-span-1"
          data={portfoliosOverview}
          chart={
            <GoogleChart
              chartType="PieChart"
              width="100%"
              data={portfoliosOverview}
              options={portfolioOverviewChartOptions}
            />
          }
        />

        <ChartCard
          id="maintenanceOverview"
          title="Maintenance"
          cardClassName="col-span-1"
          data={maintenanceOverview}
          chart={
            <GoogleChart
              chartType="PieChart"
              width="100%"
              data={maintenanceOverview}
              options={maintenanceOverviewOptions}
            />
          }
        />

        <ChartCard
          id="expiringLease"
          title="Expiring Leases"
          cardClassName="col-span-1"
          data={expiringLeases}
          chart={
            <GoogleChart
              chartType="PieChart"
              width="100%"
              data={expiringLeases}
              options={expiringLeasesOptions}
            />
          }
        />

        <ChartCard
          id="propertyRevenueInformation"
          title="Property Revenue Information"
          cardClassName="col-span-3"
          chart={
            <>
              <div className="flex justify-center items-end gap-4 py-2">
                <InputObjectSelectField
                  //label="Select Portfolio"
                  notImp
                  name="portfolio"
                  id="portfolio"
                  placeholder="Select a Portfolio"
                  value={selectedPortfolioID}
                  onChange={(e) => {
                    getPropertyRevenueData(parseInt(e.target.value), 0);
                    getProperties(e.target.value);
                  }}
                  options={allPortfolios}
                  optionValue="ID"
                  optionName="portfolioName"
                  style="rounded-lg"
                />

                <InputObjectSelectField
                  //label="Select Property"
                  name="property"
                  id="property"
                  notImp
                  dataCy="property"
                  placeholder="Select a property"
                  value={selectedPropertyId}
                  onChange={(e) => {
                    getPropertyRevenueData(
                      selectedPortfolioID,
                      parseInt(e.target.value)
                    );
                    setSelectedPropertyId(parseInt(e.target.value));
                  }}
                  options={properties}
                  optionValue="ID"
                  optionName="propertyName"
                  style="rounded-md"
                />

                <button
                  id="resetValues"
                  name="resetValues"
                  data-cy="resetValues"
                  onClick={() => {
                    setFilteredPropRevenue(allPortfoliosPropRevenue);
                    setPropertyRevenue(allPortfoliosPropRevenue);
                    setSelectedPortfolioID("");
                    setSelectedPropertyId("");
                  }}
                  className="underline text-blue-52 cursor-pointer items-end active:scale-95 transform transition duration-100"
                >
                  Reset
                </button>

                <InputObjectSelectField
                  //label="Select Property"
                  name="reportPeriod"
                  id="reportPeriod"
                  dataCy="reportPeriod"
                  notImp
                  placeholder="Select a Report Period"
                  value={selectedReportPeriod}
                  onChange={(e) => {
                    handleFilteredRevenueData(parseInt(e.target.value));
                    setSelectedReportPeriod(parseInt(e.target.value));
                  }}
                  options={[
                    { name: "Last Quarter", value: 3 },
                    { name: "Last 6 Months", value: 6 },
                    { name: "Last 12 Months", value: 12 },
                  ]}
                  optionValue="value"
                  optionName="name"
                  style="rounded-md"
                />
              </div>

              <GoogleChart
                chartType="Bar"
                width="100%"
                data={filteredPropRevenue}
                options={propertyRevenueOptions}
              />
            </>
          }
        />

        {/* 
        <ChartCard
          title="Vendor Invoice"
          cardClassName="col-span-1"
          chartClassName="text-sm"
          chart={
            <TableComponent
              tableId="VendorInvoice"
              data={vendorInvoice}
              columns={[
                { key: "orderNo", title: "Order No.", width: "80px" },
                {
                  key: "transactionId",
                  title: "Transaction ID",
                  width: "100px",
                  getCellStyles: (row) => {
                    return { color: "#6C94CE" };
                  },
                },
                { key: "amount", title: "Amount", width: "60px" },
                {
                  key: "status",
                  title: "Status",
                  width: "60px",
                  dataClassName: "font-medium",
                  getCellStyles: (row) => {
                    if (row.status === "CLOSED") {
                      return { color: "#9BD9BE" };
                    } else return { color: "#FD9C73" };
                  },
                },
              ]}
              rowOnClick={() => {}}
              //initialSort={{ key: "status", direction: "descending" }}
            />
          }
        /> */}
      </section>
    </>
  );
};

export default Dashboard;

export const ChartCard = (props) => {
  const hasData = props.data && props.data.length > 0;
  const hasNonZeroData =
    hasData &&
    props.data.some(([label, value], index) => index !== 0 && value !== 0);

  if (hasNonZeroData === false) {
    return (
      <div
        className={
          props.cardClassName +
          " border-2 rounded-lg shadow-2xl overflow-hidden h-full hover:border-blue-54"
        }
      >
        <h3 id={props.id} className="text-center font-semibold text-lg px-2">
          {props.title}
        </h3>
        <div className={props.chartClassName + " p-4 text-blue-52 "}>
          No data available
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        props.cardClassName +
        " border-2 rounded-lg shadow-2xl overflow-hidden h-full hover:border-blue-54"
      }
    >
      <h3 id={props.id} className="text-center font-semibold text-lg px-2">
        {props.title}
      </h3>
      <div className={props.chartClassName + " px-4"}>{props.chart}</div>
    </div>
  );
};
