import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";

let webApi = new WebApimanager();

// Rental Ledger Report APIs

// API to Get Rental Ledger by Tennat Code
export const GetRentalLedgerByTenantCodeAPI = (tenantCode, requestData) => {
  return webApi
    .post(`/reports/rentalLedger/tenantCode/${tenantCode}`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage); // Optional: You can throw an error to be caught later
      }
      return res; // Return the response on success
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Rental Ledger couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error; // Throw error so it can be handled in the calling function
    });
};

// API to Download Rental Ledger PDf
export const DownloadRentalLedgerPdfAPI = (requestData) => {
  return webApi
    .post(`/report/rentalLedger/pdf`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Rental Ledger Report PDF couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//Tenant Directory Report
export const GetTenantDirectoryReportAPI = (propertyId) => {
  return webApi
    .get(`/reports/tenantDirectory/property/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Tenant Directory Report couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Download Tenant Directory PDf
export const DownloadTenantDirectoryPdfAPI = (requestData) => {
  return webApi
    .post(`/report/tenantDirectory/pdf`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Tenant Directory Report PDF couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//Get Delinquency Report
export const GetDelinquentReport = (clientId, portfolioID) => {
  return webApi
    .get(
      `/reports/tenantDelinquency/client/${clientId}/portfolio/${portfolioID}`
    )
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Delinqueny Report couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Download Tenant Delinquency PDf
export const DownloadTenantDelinquencyPdfAPI = (requestData) => {
  return webApi
    .post(`/report/delinquencyReport/pdf`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Tenant Delinqency Report PDF couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//Get Vacate Notice Report
export const GetVacateNoticeReport = (clientId, portfolioID) => {
  return webApi
    .get(`/reports/vacateNotices/client/${clientId}/portfolio/${portfolioID}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Vacate Notice Report couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//Get Unit Directory Report
export const GetUnitDirectoryReport = (propertyId) => {
  return webApi
    .get(`/units/property/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Unit Directory Report couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Download Unit Directory Report PDf
export const DownloadUnitDirectoryReportPdfAPI = (requestData) => {
  return webApi
    .post(`/report/unitDirectory/pdf`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Unit Directory Report PDF couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//Get Work Order Report
export const GetWorkOrderReportAPI = (vendorId) => {
  return webApi
    .get(`/reports/workOrder/vendor/${vendorId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Work Order Report couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Download Work Order Report PDf
export const DownloadWorkOrderReportPdfAPI = (requestData) => {
  return webApi
    .post(`/report/workOrder/pdf`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Work Order Report PDF couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

//Get Rent Roll Report
export const GetRentRollReport = (propertyId) => {
  return webApi
    .get(`/reports/rentalRoll/property/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Rent Roll Report couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Download Work Order Report PDf
export const DownloadRentRollReportPdfAPI = (requestData) => {
  return webApi
    .post(`/report/rentRoll/pdf`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Rent Roll Report PDF couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};
